import { Directive, Input, ElementRef, ViewContainerRef, NgZone, Inject, Optional } from '@angular/core';
import { MatTooltip, MAT_TOOLTIP_SCROLL_STRATEGY, MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';

@Directive({
  selector: '[mCalendarTooltip]',
  exportAs:'m-calendar-tooltip'
})
export class CalendarTooltipDirective extends MatTooltip{

  @Input()
  get mCalendarTooltip(){
    return this.message;
  }

  set mCalendarTooltip(value: string){
    this.message = value;
  }

  constructor(
    _overlay:Overlay,
    _elementRef:ElementRef,
    _scrollDispatcher:ScrollDispatcher,
    _viewContainerRef:ViewContainerRef,
    _ngZone:NgZone,
    _platform:Platform,
    _ariaDescriber: AriaDescriber,
    _focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) _scrollStrategy:any,
    @Optional() _dir:Directionality,
    @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) _defaultOptions: MatTooltipDefaultOptions
  ) { 
    super(
      _overlay,
      _elementRef,
      _scrollDispatcher,
      _viewContainerRef,
      _ngZone,
      _platform,
      _ariaDescriber,
      _focusMonitor,
      _scrollStrategy,
      _dir,
      _defaultOptions
      );
  }
}
