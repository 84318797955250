import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'm-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @Input() chartData:any;
	// public lineChartOptions: any = {
	// 	scaleShowVerticalLines: false,
  //   responsive: true,
  //   legend: { position: 'bottom' },
  //   valueFormatString: "$#0",
  //   yAxes: [
  //     {
  //         display: true,
  //         ticks: {
  //           fontSize: 14
  //         }
  //     }
  //   ]
  // };
  public lineChartOptions: any ;
	public lineChartLabels: string[];
	public lineChartType: string = 'line';
  public lineChartLegend: boolean = true;
  public lineChartColors:Array<any> ;
  public lineChartData: any[] ;

	constructor () { }

	ngOnInit () {
    this.lineChartData = this.chartData.lineChartData;
    this.lineChartLabels = this.chartData.label;
    this.lineChartColors = this.chartData.lineChartColors;
    let lineoptlabel = this;
    this.lineChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      legend: {
        display: lineoptlabel.chartData.legendDisplay, 
        position: 'bottom' ,
        labels: {
          boxWidth: 13,
          fontSize: 12,
          fontFamily: "'Poppins'",
          fontColor: '#666666',
          padding: 25
        }
      },
      scales : {
        yAxes: [
          {
              display: true,
              ticks: {
                callback: function(label) {
                  return label +' '+ lineoptlabel.chartData.labelunit + '   ';
              },
                fontSize: 10,
                fontFamily: "'Poppins'",
                fontColor: '#7b7e8a',
                stepSize: this.chartData.stepSize,
                min:this.chartData.min,
                beginAtZero: this.chartData.beginAtZero
              }
          }
        ],
        xAxes : [ {
            gridLines : {
                display : true
            },
            ticks:{
              fontSize: 10,
              fontFamily: "'Poppins'",
              fontColor: '#7b7e8a',
            }
        } ]
      }
    }
	}

	// events
	chartClicked (e: any): void {
	}

	chartHovered (e: any): void {
	}

}
