import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class TokenStorage {
	lsk = 'iSales!2020';
	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('accessToken');
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('refreshToken');
		return of(token);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		// const roles: any = localStorage.getItem('userRoles');
		const jsonData: any = localStorage.getItem('ud');
		if(jsonData){
			const udata = this.decryptUserData(jsonData);
			try {
				if(!udata || !udata.Roles){
					return of([]);
				}
				return of([udata.Roles.toLowerCase()]);
			} catch (e) {}
		}else{
			return of([]);
		}
		
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		if(token && token!= 'null' && token!='undefined'){
			localStorage.setItem('accessToken', token);
		}
		return this;	
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		if(token && token!= 'null' && token!='undefined'){
			localStorage.setItem('refreshToken', token);
		}
		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			localStorage.setItem('userRoles', JSON.stringify(roles));
		}

		return this;
	}

	/**
	 * Set full name
	 * @param name
	 * @returns {TokenStorage}
	 */
	public setUserFullName(name: string): TokenStorage {
		localStorage.setItem('fullName', name);

		return this;
	}

	/**
	 * Get full name
	 * @returns {Observable<string>}
	 */
	public getUserFullName(): Observable<string> {
		// const name: string = <string>localStorage.getItem('fullName');
		const jsonData: any = localStorage.getItem('ud');
		if(jsonData){
			const udata = this.decryptUserData(jsonData);
			return of(udata.FullName);
		}else{
			return of('');
		}
	}

	/**
	 * Set Email
	 * @param email
	 * @returns {TokenStorage}
	 */
	public setUserEmail(email: string): TokenStorage {
		localStorage.setItem('userEmail', email);
		return this;
	}
	

	/**
	 * Get Email
	 * @returns {Observable<string>}
	 */
	public getUserEmail(): Observable<string> {
		// const email: string = <string>localStorage.getItem('userEmail');
		// return of(email);
		const jsonData: any = localStorage.getItem('ud');
		if(jsonData){
			const udata = this.decryptUserData(jsonData);
			return of(udata.Email);
		}else{
			return of('');
		}
	}

	/**
	 * Set Email
	 * @param id
	 * @returns {TokenStorage}
	 */
	public setUserId(id: any,role:any): TokenStorage {
		localStorage.setItem('userId', id);		
		return this;
	}

	/**
	 * Get Email
	 * @returns {Observable<any>}
	 */
	public getUserId(): Observable<any> {
		// const id: any = <any>localStorage.getItem('userId');
		const jsonData: any = localStorage.getItem('ud');
		if(jsonData){
			const udata = this.decryptUserData(jsonData);
			return of(udata.Id);
		}else{
			return of(null);
		}
	}

	public setUserData(userData:any):TokenStorage {
		this.encryptUserData(JSON.stringify(userData));
		let toEncryptData = this.encryptUserData(JSON.stringify(userData));
		localStorage.setItem('ud',toEncryptData);
		return this;
	}

	public getUserData(): Observable<any> {
		const jsonData: any = <any>localStorage.getItem('ud');
		if(jsonData){
			const udata = this.decryptUserData(jsonData);
			return of(udata);
		}else{
			return of({});
		}
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userRoles');
		localStorage.removeItem('fullName');
		localStorage.removeItem('userEmail');
		localStorage.removeItem('userId');
		localStorage.removeItem('ud');
	}


	encryptUserData(toEncryptData){
		try {
			return CryptoJS.AES.encrypt(toEncryptData, this.lsk).toString();
		  } catch (e) {
			console.error('Invalid Login!');
		  }
	}

	decryptUserData(toDecryptData){
		try {
			const bytes = CryptoJS.AES.decrypt(toDecryptData, this.lsk);
			if (bytes.toString()) {
			  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			}
			return toDecryptData;
		  } catch (e) {
			console.error('Invalid Login!');
		}
	}
}
