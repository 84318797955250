import { Injectable } from '@angular/core';
import { MenuList } from '../../models/menu-list';
import { TokenStorage } from '../../auth/token-storage.service';

@Injectable()
export class MenuListService {

  menuListConfig:MenuList;
  public userMenuListArr:any[];

  constructor(private tokenStorageService:TokenStorage) {
    this.menuListConfig = new MenuList();
    // this.getAndSetUserMenuList();
  }

  setRedirectUrl(permissionListData){
    // let userMenuPermissions = this.tokenStorageService.getUserData();
    let toSetMenuArr = [];
    this.userMenuListArr= JSON.parse(atob(permissionListData));
    this.userMenuListArr.forEach((menuItem) => {
      let menuItemIndex = this.menuListConfig.config.findIndex((conIndx) => {
        return conIndx.menuId == menuItem.permission;
      });
      if(menuItemIndex > -1){
        toSetMenuArr.push(this.menuListConfig.config[menuItemIndex].menuUrl);
      }      
    });
    if(toSetMenuArr.length > 0){
      return toSetMenuArr[0];
    }else{
      return '/profile';
    }
  }

  getAndSetUserMenuList(permissionListData){
    // this.tokenStorageService.getUserData().subscribe((uData) => {
      let toSetMenuArr = [];
      this.userMenuListArr= JSON.parse(atob(permissionListData));
      this.userMenuListArr.forEach((menuItem) => {
        let menuItemIndex = this.menuListConfig.config.findIndex((conIndx) => {
          return conIndx.menuId == menuItem.permission;
        });
        if(menuItemIndex > -1){
          toSetMenuArr.push(this.menuListConfig.config[menuItemIndex].menuViewName);
        }
      });
      return toSetMenuArr;
    // });
  }

  getMenuViewById(menuListId:any){
    let menuViewIndex = this.menuListConfig.config.indexOf((mItem) => { return mItem.menuId == menuListId });
    return this.menuListConfig.config[menuViewIndex].menuViewName;
  }

  asideMenuItemToggle(){

  }
  
}
