// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
					{
						title: 'Actions',
						root: true,
						icon: 'flaticon-add',
						toggle: 'click',
						translate: 'MENU.ACTIONS',
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'Create New Post',
									page: '/header/actions',
									icon: 'flaticon-file',
									translate: 'MENU.CREATE_POST',
									aside: {
										self: {
											bullet: 'dot'
										},
										items: [
											{
												section: 'Departments'
											},
											{
												title: 'Resources',
												desc: '',
												icon: 'flaticon-layers',
												bullet: 'dot',
												root: true,
												submenu: [
													{
														title: 'Create New Post',
														page: '/header/actions',
													},
													{
														title: 'Timesheet',
														tooltip: 'Non functional dummy link',
													},
													{
														title: 'Payroll',
														tooltip: 'Non functional dummy link',
													},
													{
														title: 'Contacts',
														tooltip: 'Non functional dummy link',
													}
												]
											}
										]
									}
								},
								{
									title: 'Generate Reports',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-diagram',
									badge: {
										type: 'm-badge--success',
										value: '2'
									},
								},
								{
									title: 'Manage Orders',
									icon: 'flaticon-business',
									submenu: {
										type: 'classic',
										alignment: 'right',
										bullet: 'line',
										items: [
											{
												title: 'Latest Orders',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Pending Orders',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Processed Orders',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Delivery Reports',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Payments',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Customers',
												tooltip: 'Non functional dummy link',
												icon: '',
											}
										]
									}
								},
								{
									title: 'Customer Feedbacks',
									page: '/#',
									icon: 'flaticon-chat-1',
									submenu: {
										type: 'classic',
										alignment: 'right',
										bullet: 'dot',
										items: [
											{
												title: 'Customer Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Supplier Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Reviewed Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Resolved Feedbacks',
												tooltip: 'Non functional dummy link',
												icon: '',
											},
											{
												title: 'Feedback Reports',
												tooltip: 'Non functional dummy link',
												icon: '',
											}
										]
									}
								},
								{
									title: 'Register Member',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-users',
								}
							]
						}
					},
					{
						title: 'Reports',
						root: true,
						icon: 'flaticon-line-graph',
						toggle: 'click',
						translate: 'MENU.REPORTS',
						submenu: {
							type: 'mega',
							width: '1000px',
							alignment: 'left',
							columns: [
								{
									heading: {
										heading: true,
										title: 'Finance Reports',
									},
									items: [
										{
											title: 'Annual Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-map',
										},
										{
											title: 'HR Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-user',
										},
										{
											title: 'IPO Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-clipboard',
										},
										{
											title: 'Finance Margins',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-graphic-1',
										},
										{
											title: 'Revenue Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-graphic-2',
										}
									]
								},
								{
									bullet: 'line',
									heading: {
										heading: true,
										title: 'Project Reports',
									},
									items: [
										{
											title: 'Coca Cola CRM',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title:
												'Delta Airlines Booking Site',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Malibu Accounting',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Vineseed Website Rewamp',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Zircon Mobile App',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Mercury CMS',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								},
								{
									bullet: 'dot',
									heading: {
										heading: true,
										title: 'HR Reports',
									},
									items: [
										{
											title: 'Staff Directory',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Client Directory',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Salary Reports',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Staff Payslips',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Corporate Expenses',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Project Expenses',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								},
								{
									heading: {
										heading: true,
										title: 'Reporting Apps',
										icon: '',
									},
									items: [
										{
											title: 'Report Adjusments',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Sources & Mediums',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Reporting Settings',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Conversions',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Report Flows',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Audit & Logs',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								}
							]
						}
					},
					{
						title: 'Apps',
						root: true,
						icon: 'flaticon-paper-plane',
						toggle: 'click',
						translate: 'MENU.APPS',
						badge: {
							type: 'm-badge--brand m-badge--wide',
							value: 'new',
							translate: 'MENU.NEW',
						},
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'eCommerce',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-business',
									submenu: {
										type: 'classic',
										alignment: 'right',
										items: [
											{
												title: 'Customers',
												page: '/ecommerce/customers',
												icon: 'flaticon-users',
											},
											{
												title: 'Orders',
												page: '/ecommerce/orders',
												icon: 'flaticon-interface-1',
											},
											{
												title: 'Products',
												page: '/ecommerce/products',
												icon: 'flaticon-list-1',
											}
										]
									}
								},
								{
									title: 'Audience',
									page: '/crud/datatable_v1',
									icon: 'flaticon-computer',
									submenu: {
										type: 'classic',
										alignment: 'right',
										items: [
											{
												title: 'Active Users',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-users',
											},
											{
												title: 'User Explorer',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-interface-1',
											},
											{
												title: 'Users Flows',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-lifebuoy',
											},
											{
												title: 'Market Segments',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-graphic-1',
											},
											{
												title: 'User Reports',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-graphic',
											}
										]
									}
								},
								{
									title: 'Marketing',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-map',
								},
								{
									title: 'Campaigns',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-graphic-2',
									badge: {
										type: 'm-badge--success',
										value: '3'
									}
								},
								{
									title: 'Cloud Manager',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-infinity',
									submenu: {
										type: 'classic',
										alignment: 'left',
										items: [
											{
												title: 'File Upload',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-add',
												badge: {
													type: 'm-badge--danger',
													value: '3'
												}
											},
											{
												title: 'File Attributes',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-signs-1',
											},
											{
												title: 'Folders',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-folder',
											},
											{
												title: 'System Settings',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-cogwheel-2',
											}
										]
									}
								}
							]
						}
					}
				]
			},
			aside: {
				self: {},
				items: [
					{section: 'Min meny', permissions:['mySalaryList','myAttendance','mySchedule','myLeaveRequests','myOrderRequests','contactRecruitment','contactAdmin','StatisticsList']},
					{
						title: 'Statistik',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-chart-pie',
						page: '/statistics/list',
						menuViewName:'StatisticsList',
						permissions:['StatisticsList']
					},
					{
						title: 'Min lön',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-money-check',
						page: '/my-salary/list',
						translate: 'MENU.MY_SALARY',
						menuViewName:'mySalaryList',
						permissions:['mySalaryList']
					},
					{
						title: 'Min närvaro',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-calendar-check',
						page: '/my-attendance/calendar',
						translate: 'MENU.MY_ATTENDANCE',
						menuViewName:'myAttendance',
						permissions:['myAttendance']
					},
					{
						title: 'Min försäljning',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-dollar-sign',
						page: '/orders/myorder',
						menuViewName:'myOrderRequests',
						permissions:['myOrderRequests']
					},
					
					{
						title: 'Arbetsschema',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-calendar-minus',
						page: '/my-schedule',
						translate: 'MENU.WORK_SCHEDULE',
						menuViewName:'mySchedule',
						permissions:['mySchedule']
					},
					{
						title: 'Begär frånvaro',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-calendar-alt',
						page: '/my-leave-requests',
						menuViewName:'myLeaveRequests',
						permissions:['myLeaveRequests']
					},
					{
						title:'Rekrytera en vän',
						root: true,
						page:'/management/recruitment',
						permissions:['contactRecruitment'],
						menuViewName:'contactRecruitment',
						icon: 'far fa-address-card'
					},
					{
						title:'Kontakta Ledningsgrupp',
						page:'/management/contact-admin',
						permissions:['contactAdmin'],
						menuViewName:'contactAdmin',
						icon: 'fas fa-envelope'
									
					},
					
					// {
					// 	title: 'Min Ånger',
					// 	desc: 'Some description goes here',
					// 	root: true,
					// 	icon: 'fa fa-ban',
					// 	page: '/orders/myregret',
					// 	menuViewName:'myLeaveRequests',
					// 	permissions:['myLeaveRequests']
					// },
					// {
					// 	title: 'Kontakt',
					// 	desc: 'Some description goes here',
					// 	root: true,
					// 	icon: 'fas fa-envelope',
					// 	translate: 'MENU.CONTACT',
					// 	submenu: [
					// 		{
					// 			title:'Rekrytera en vän',
					// 			page:'/management/recruitment',
					// 			permissions:['contactRecruitment'],
					// 			menuViewName:'contactRecruitment'	
					// 		},
					// 		{
					// 			title:'Kontakta Ledningsgrupp',
					// 			page:'/management/contact-admin',
					// 			permissions:['contactAdmin'],
					// 			menuViewName:'contactAdmin'	
					// 		}
					// 	],
					// 	permissions:['contactRecruitment','contactAdmin']
					// },

					{section: 'Toppanställda', permissions:['topEmployee']},
					{
						title: 'Toppanställda',
						desc: 'Some description goes here',
						root: true,
						icon: 'fa fa-trophy',
						page: '/personal/employee/top',
						menuViewName:'topEmployee',
						permissions:['topEmployee']
					},

					{section: 'Personal', permissions:['employeeInfo','allEmployeeSchema','attendanceSales','attendanceManagement','attendanceOverview','empTargetHoursList']},
					{
						title: 'Personallista',
						root: true,
						icon: 'fas fa-user',
						translate:'MENU.PERSONAL.EMPLOYEE_LIST',
						page:'/personal/employee/list',
						permissions:['employeeInfo'],
						menuViewName:'employeeInfo'
					},
					{
						title: 'Schedule',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-calendar',
						page: '/schema',
						translate: 'MENU.SCHEDULE',
						permissions:['allEmployeeSchema'],
						menuViewName:'allEmployeeSchema'
					},
					{
						title: 'Attendance Report',
						desc: 'Some description goes here',
						root: true,
						icon: 'far fa-clock',
						translate: 'MENU.ATTENDANCE_REPORT',
						submenu: [
							{
								title:'Säljare',
								page:'/attendance/sales/report',
								permissions:['attendanceSales'],
								menuViewName:'attendanceSales'	
							},
							{
								title:'Ledning',
								page:'/attendance/leader/report',
								permissions:['attendanceManagement'],
								menuViewName:'attendanceManagement'	
							},
							{
								title:'Närvaro översikt',
								page:'/attendance/overview',
								permissions:['attendanceOverview'],
								menuViewName:'attendanceOverview'
							},
							{
								title: 'Tidsöversikt',
								page:'/project/emptargetHours/list',
								permissions:['empTargetHoursList'],
								menuViewName:'empTargetHoursList',
								translate:'MENU.TIMER_OVERVIEW'
							},
						],
						permissions:['attendanceSales','attendanceManagement','attendanceOverview','empTargetHoursList']
					},
					{
						title: 'Hantering',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-clipboard-list',
						submenu: [
							{
								title:'Ledigheter',
								page:'/requests',
								permissions:['leaveRequests'],
								menuViewName:'leaveRequests'
							},	
							{
								title:'Profiländringar',
								page:'/employee-requests/list',
								permissions:['employeeRequests'],
								menuViewName:'employeeRequests'
							}
						],
						permissions:['leaveRequests','employeeRequests']
					},
					{section: 'Admin', permissions:['importHours','importSales','importRegrets','clientList','projectList','productList','provisionList','hoursList','targetHoursList','benefitList','bonusSettingsList','departmentList','departmentCalendar','employeeRequestSettings','employeeLogs','menuPermissions']},
					{
						title: 'Import & Export',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-download',
						translate: 'MENU.IMPORT_ORDER_TIME',
						submenu: [
							{
								title: 'Tid',
								page:'/import/import-time/list',
								permissions:['importHours'],
								menuViewName:'importHours'
							},	
							{
								title: 'Order',
								page:'/import/import-order/list',
								permissions:['importSales'],
								menuViewName:'importSales'
							},
							{
								title: 'Ånger',
								page:'/import/import-regrets/list',
								permissions:['importRegrets'],
								menuViewName:'importRegrets'
							},
							{
								title: 'Total Order',
								page:'/orders/totalorder',
								permissions:['importSales'],
								menuViewName:'importSales'
							},
							// {
							// 	title: 'Total Ånger',
							// 	page:'/orders/totalregret',
							// 	permissions:['importSales'],
							// 	menuViewName:'importSales'
							// }
						],
						permissions:['importHours','importSales','importRegrets']
					},
					{
						title: 'Project Settings',
						root: true,
						icon: 'fas fa-cog',
						translate:'MENU.PROJECT_SETTINGS',
						submenu: [	
							{
								title: 'Client',
								page:'/project/client/list',
								translate:'MENU.PROJECT_SETTINGS_MENU.CLIENT',
								permissions:['clientList'],
								menuViewName:'clientList'
							},						
							{
								title: 'Project',
								page:'/project/projects/list',
								translate:'MENU.PROJECT_SETTINGS_MENU.PROJECT',
								permissions:['projectList'],
								menuViewName:'projectList'
							},
							{
								title: 'Produkt',
								page:'/project/products/list',
								permissions:['productList'],
								menuViewName:'productList'
							},
							{
								title: 'Provision',
								page:'/project/provisions/list',
								translate:'MENU.PROJECT_SETTINGS_MENU.PROVISION',
								permissions:['provisionList'],
								menuViewName:'provisionList'
							},
							{
								title: 'Timersättning',
								page:'/project/timersattning/list',
								translate:'MENU.PROJECT_SETTINGS_MENU.TIMER_SATTNING',
								permissions:['hoursList'],
								menuViewName:'hoursList'
							},
							{
								title: 'Månadstimmar',
								page:'/project/targetHours/list',
								permissions:['targetHoursList'],
								menuViewName:'targetHoursList',
								translate:'MENU.MONTHLY_HOURS'
							}
						],
						permissions:['clientList','projectList','productList','provisionList','hoursList','targetHoursList']
					},
					{
						title: 'Bonusinställningar',
						root: true,
						icon: 'fas fa-hand-holding-usd',
						submenu: [	
							
							{
								title: 'Bonustyp',
								page:'/project/benefit/list',
								permissions:['benefitList'],
								menuViewName:'benefitList',
								translate:'MENU.BONUS_TYPE'
							},
							
							{
								title: 'Rekrytering',
								page:'/project/bonusSetting/list',
								permissions:['bonusSettingsList'],
								menuViewName:'bonusSettingsList',
								translate:'MENU.RECRUITMENT'
							},
							{
								title: 'Narvaro Tid',
								page:'/project/narvarotid/list',
								permissions:['narvaroTidList'],
								menuViewName:'narvaroTidList',
								translate:'MENU.ATTENDANCE_TIME'
							},
							{
								title: 'Trappa Bonus',
								page:'/project/trappa/list',
								permissions:['trappaList'],
								menuViewName:'trappaList',
								translate:'MENU.BONUS'
							},
							// {
							// 	title: 'Trappa Närvaro %',
							// 	page:'/project/trappaNarvaro/list',
							// 	permissions:['trappaNarvaro'],
							// 	menuViewName:'trappaNarvaro',
							// 	translate:'MENU.NARVARO'
							// },
							// {
							// 	title: 'Trappa Grouping',
							// 	page:'/project/trappaGrouping/list',
							// 	permissions:['trappaGrouping'],
							// 	menuViewName:'trappaGrouping',
							
							// }
						],
						permissions:['benefitList','bonusSettingsList','narvaroTidList','trappaNarvaro','trappaGrouping']
					},
					{
						title: 'System Settings',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-cogs',
						translate: 'MENU.SYSTEM_SETTINGS',
						submenu:[
							{
								title: 'Avdelning',
								page:'/systemsetting/department/list',
								permissions:['departmentList'],
								menuViewName:'departmentList'
							},
							{
								title:'Kalender',
								page:'/systemsetting/department-calendar',
								permissions:['departmentCalendar'],
								menuViewName:'departmentCalendar'
							},							
							{
								title: 'Profilhantering',
								page:'/employee-requests/request-type-settings',
								permissions:['employeeRequestSettings'],
								menuViewName:'employeeRequestSettings'
							},
							{
								title: 'Logs',
								page:'/personal/employee/logs',
								permissions:['employeeLogs'],
								menuViewName:'employeeLogs'
							},
							{
								title: 'Menyinställningar',
								page:'/systemsetting/menu-permissions',
								permissions:['menuPermissions'],
								menuViewName:'menuPermissions'
							},
							{
								title:'Flygläge',
								page:'/systemsetting/flight-mode',
								permissions:['flightMode'],
								menuViewName:'flightMode'
							},
							{
								title:'Skicka Notiser',
								page:'/systemsetting/noticer-type/list',
								permissions:['noticerType'],
								menuViewName:'noticerType'
							}
						],
						permissions:['departmentList','departmentCalendar','employeeRequestSettings','employeeLogs','menuPermissions','noticerType']
					},
					{section: 'Kvalitet', permissions:['qualityReport','qualitySettingsList']},					
					{
						title:'Kvalitetsrapport',
						page:'/quality/quality-report/list',
						root: true,
						icon: 'far fa-clipboard',
						permissions:['qualityReport'],
						menuViewName:'qualityReport',
						translate:'COMMON.QUALITY_REPORT'
					},
					{
						title:'Kvalitetstyp',
						page:'/quality/settings',
						root: true,
						icon: 'far fa-check-square',
						translate:'MENU.QUALITY_MENU.SETTINGS',
						permissions:['qualitySettingsList'],
						menuViewName:'qualitySettingsList'
					},
					{section: 'Ekonomi', permissions:['economySalaryTable','economyOverviewList','economyMangementList','economyBonusList']},
					{
						title: 'Lön',
						page:'/economy/wages/list',
						root: true,
						icon: 'far fa-money-bill-alt',
						permissions:['economySalaryTable'],
						menuViewName:'economySalaryTable'
					},
					{
						title: 'Ekonomisk översikt',
						page:'/economy/overview/list',
						root: true,
						icon: 'fas fa-money-check',
						permissions:['economyOverviewList'],
						menuViewName:'economyOverviewList'
					},
					{
						title:'Ledningslön',
						page:'/economy/management/list',
						root: true,
						icon: 'far fa-credit-card',
						permissions:['economyMangementList'],
						menuViewName:'economyMangementList'
					},
					{
						title:'Bonus',
						page:'/economy/bonus/list',
						root: true,
						icon: 'fas fa-coins',
						permissions:['economyBonusList'],
						menuViewName:'economyBonusList'
					}
				]
			}
		};
	}
}
