import {
	Component,
	OnInit,
	HostBinding,
	HostListener,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService } from '../../../../../core/services/pages/shared.service';

@Component({
	selector: 'm-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit,OnDestroy {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width';

	// @HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';
	@HostBinding('attr.m-dropdown-persistent') attrDropdownPersisten = 'true';

	@Input() animateShake: any;
	@Input() animateBlink: any;
	notificationCount:any = 0;
	notificationCountUnSub:any;
	userData: any = {};
    getUserData: any = {};

	constructor(private _ref:ChangeDetectorRef,private router:Router,private sharedService:SharedService) {
		// animate icon shake and dot blink
		setInterval(() => {			
			if(this.notificationCount != 0){
				this.animateShake = 'm-animate-shake';
				this.animateBlink = 'm-animate-blink';
				_ref.detectChanges();
			}
		}, 3000);
		setInterval(() => (this.animateShake = this.animateBlink = ''), 6000);
	}

	ngOnInit(): void {
		this.getUserData = this.sharedService.getRoleAndId();
        this.getUserData.userId.subscribe(id => {
            if (id) {
                this.userData.id = parseInt(id);
            }
        });
        this.getUserData.role.subscribe(role => {
            this.userData.role = role.toString();
		});
		if(this.userData.role === 'admin' || this.userData.role === 'superadmin'){
			this.router.events.subscribe((routerEvent) => {
				if(routerEvent instanceof NavigationEnd){
					this.sharedService.getNotifications().subscribe((notificationData)=>{
						if(notificationData && notificationData.count){
							this.sharedService.notificationSub.next(notificationData.count);
						}
					});
				}
			});
			this.sharedService.getNotifications().subscribe((notificationData)=>{
				if(notificationData && notificationData.count){
					this.sharedService.notificationSub.next(notificationData.count);
				}
			});
			this.notificationCountUnSub = this.sharedService.notificationObs$.subscribe((nCount) => {
				if(nCount){
					this.notificationCount = nCount;
				}
			});
		}
	}
	
	ngOnDestroy(){
		if(this.notificationCountUnSub){
			this.notificationCountUnSub.unsubscribe();
		}
	}
}
