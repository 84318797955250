export class MessengerDb {
	public static messages: any = [
		{
			id: 1,
			type: 'in',
			pic: './assets/app/media/img//users/user3.jpg',
			username: 'Megan wrote',
			text: 'Hi Bob. What time will be the meeting ?'
		},
		{
			id: 2,
			type: 'out',
			text: 'Hi Megan. It\'s at 2.30PM'
		},
		{
			id: 3,
			type: 'in',
			pic: './assets/app/media/img//users/user3.jpg',
			username: 'Megan wrote',
			text: 'Will the development team be joining ?'
		},
		{
			id: 4,
			type: 'out',
			text: 'Ja sure. I invited them as well'
		},
		{
			id: 5,
			type: 'datetime',
			text: '2:30PM'
		},
		{
			id: 6,
			type: 'in',
			pic: './assets/app/media/img//users/user3.jpg',
			username: 'Megan wrote',
			text: 'Noted. For the Coca-Cola Mobile App project as well ?'
		},
		{
			id: 7,
			type: 'out',
			text: 'Ja, sure.'
		},
		{
			id: 8,
			type: 'out',
			text:
				'Please also prepare the quotation for the Loop CRM project as well.'
		},
		{
			id: 9,
			type: 'datetime',
			text: '3:15PM'
		},
		{
			id: 10,
			type: 'in',
			pic: '',
			username: 'Megan wrote',
			text: 'Noted. I will prepare it.'
		},
		{
			id: 11,
			type: 'out',
			text: 'Thanks Megan. I will see you later.'
		},
		{
			id: 12,
			type: 'in',
			pic: './assets/app/media/img//users/user3.jpg',
			username: 'Megan wrote',
			text: 'Sure. See you in the meeting soon.'
		}
	];
}
