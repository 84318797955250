import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Profile } from '../models/profile.model';
import { ApiEndpointConstants } from '../../../../../../core/models/api-endpoint-constants';
import { TokenStorage } from '../../../../../../core/auth/token-storage.service';
import { EmployeeList } from '../../../../components/personal/_core/models/employee-list.model';

const API_URL = ApiEndpointConstants.api_url;
const EMPLOYEE_URL = ApiEndpointConstants.ROUTES.EMPLOYEE_NEW;
const CLIENT_URL = ApiEndpointConstants.ROUTES.CLIENT_NEW;
const PRODUCT_URL = ApiEndpointConstants.ROUTES.PRODUCT;
const PROJECT_URL = ApiEndpointConstants.ROUTES.PROJECT;
const EMPLOYEE_PROJECT_URL = ApiEndpointConstants.ROUTES.EMPLOYEE_PROJECT;
const EMPLOYEE_PRODUCT_URL = ApiEndpointConstants.ROUTES.EMPLOYEE_PRODUCT;
const EMPLOYEE_PROVISION_URL = ApiEndpointConstants.ROUTES.EMPLOYEE_PROVISION;
const EMPLOYEE_INDIVIDUAL_SALARY_URL = ApiEndpointConstants.ROUTES.EMPLOYEE_INDIVIDUAL_SALARY;



@Injectable()
export class ProfileService {  

  private employee_edit_bank = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_EDIT_BANK;
  private department_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT;
  private espMonthlyReport_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.MONTHLY_SALARY_PERIOD;
  private employee_edit_employment = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_EDIT_EMPLOYMENT;
  private employee_edit_agreement = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_EDIT_AGREEMENT;
  private employee_edit_leave = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_EDIT_LEAVE;
  private employee_edit_fixedSalary = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_EDIT_FIXED_SALARY;
  private employee_edit_socialfee = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_EDIT_SOCIAL_FEE;
  private employee_edit_holiday_pay = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_EDIT_HOLIDAY_PAY;
  private employee_department_salary = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_DEPARTMENT_SALARY;
  private employee_attendance_statuswise_summary = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ATTENDANCE_EMPLOYEE_STATUSWISE_SUMMARY;
  private employee_delete_leave = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_DELETE_LEAVE;
  private profile_img_upload_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.USER_PROFILE_PHOTO_UPLOAD;
  private status_change_api_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.PROFILE_STATUS_CHANGE;
  private save_enquiry = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.SAVE_ENQUIRY;
  private get_enquiry_by_id = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_ENQUIRY_BY_ID;
  private get_all_enquires_for_employee = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_ALL_ENQUIRIES_FOR_EMPLOYEE;    
  private get_all_enquiries_by_status_for_employee = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_ALL_ENQUIRIES_BY_STATUS_FOR_EMPLOYEE;
  private get_request_type_list = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_ALL_REQUEST_TYPE_LIST;
  private employee_api_url_alt = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_NEW;
  private emp_targetList_api_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.SEARCH_EMP_TARGET_SETTINGS;
  private bonus_summary_api_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_BONUS;
  private monthly_bonus_summary_api_url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.EMPLOYEE_BONUS_MONTHLY;

  constructor(private http: HttpClient,private tokenStorage: TokenStorage) {}

  getRoleAndId():Observable<any>{
    let userData:any = {};
    userData.role = this.tokenStorage.getUserRoles().pipe(role => role);
    userData.userId = this.tokenStorage.getUserId().pipe(id => id);
    return userData;
  }

  getProfile(id:any):Observable<Profile>{
    return this.http.get(`${API_URL}${EMPLOYEE_URL}/${id}`).pipe(map((res:any) => res),catchError(this.handleError('getProfile')));
  }

  getClientList():Observable<any>{
    return this.http.get(`${API_URL}${CLIENT_URL}/GetAll`).pipe(map((res:any)=>res),catchError(this.handleError('clientList')));
  }

  getProjectList():Observable<any>{
    return this.http.get(`${API_URL}${PROJECT_URL}/GetAllProject`).pipe(map((res:any)=>res),catchError(this.handleError('projectList')));
  }

  getProductList():Observable<any>{
    return this.http.get(`${API_URL}${PRODUCT_URL}/ProductList`).pipe(map((res:any)=>res),catchError(this.handleError('productList',[])));
  }

  getRecruiterList():Observable<any>{
    return this.http.get(`${API_URL}${EMPLOYEE_URL}/GetByRole/HR`).pipe(map((res:any)=>res),catchError(this.handleError('HRList',[])));
  }

  getDepartmentList():Observable<any>{
    return this.http.get(`${this.department_url}`).pipe(map((res:any)=>res),catchError(this.handleError('departmentList',[])));
  }
  
  getESPMonthlyReport(espData):Observable<any>{
    return this.http.post(`${this.espMonthlyReport_url}`,espData).pipe(map((res:any)=>res),catchError(this.handleError('Employee project',[])));
  }

  updateProfile(profileData:Profile):Observable<any>{
    return this.http.put(`${API_URL}${EMPLOYEE_URL}/${profileData.Employee_id}`,profileData).pipe(map(res=>res),catchError(this.handleError('updateProfile',[])));
  }

  updateProfilePersonalDetails(profileData:any):Observable<any>{
    let toSendEmployee:any = {
      personal_id:profileData.personal_id,
      first_name:profileData.first_name,
      last_name:profileData.last_name,
    };
    toSendEmployee.contact = profileData.contact;
    toSendEmployee.emergencycontact = profileData.emergencycontact;
    return this.http.put(`${API_URL}${EMPLOYEE_URL}/${profileData.Employee_id}`,profileData).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  
  updateProfileBankDetails(employeeData:any):Observable<any>{
    let toSendEmployee:any = {};
     toSendEmployee = employeeData.bank;  
    return this.http.put(`${this.employee_edit_bank}/${employeeData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  updateEmployeeEmployment(employeeData:any):Observable<any>{
    let toSendEmployee:any = {
      userstatus:employeeData.userstatus,
      recruitment_id:employeeData.recruitment_id,
      Semesterval: employeeData.Semesterval,
      emp_type:employeeData.emp_type,
      loxysoft_id:employeeData.loxysoft_id,
      department:employeeData.department,    
      role:employeeData.role
    };
    return this.http.put(`${this.employee_edit_employment}/${employeeData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  updateEmployeeAgreement(employeeData:any):Observable<any>{
    let toSendEmployee:any = {};
    toSendEmployee.agreement = employeeData.agreement;
    return this.http.put(`${this.employee_edit_agreement}/${employeeData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  updateEmployeeFixedSalary(employeeData:any):Observable<any>{
    let toSendEmployee:any = {};
    toSendEmployee.fixedsalary = employeeData.fixedsalary;
    return this.http.put(`${this.employee_edit_fixedSalary}/${employeeData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  updateEmployeeSocialFee(employeeData:any):Observable<any>{
    let toSendEmployee:any = {};
    toSendEmployee.socialfee = employeeData.socialfee;
    return this.http.put(`${this.employee_edit_socialfee}/${employeeData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  updateEmployeeHolidayPayDetails(employeeData:any):Observable<any>{
    let toSendEmployee:any = {};
    toSendEmployee.holidaypay = employeeData.holidaypay;
    return this.http.put(`${this.employee_edit_holiday_pay}/${employeeData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  updateLeave(employeeData:any):Observable<any>{
    let toSendEmployee:any = {};
    toSendEmployee.leave = employeeData.leave;
    return this.http.put(`${this.employee_edit_leave}/${employeeData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('updateEmployee',[])));
  }

  addProjectForEmployee(employeeProjectData:any):Observable<any>{
    return this.http.post(`${API_URL}${EMPLOYEE_PROJECT_URL}`,employeeProjectData).pipe(map((res:any)=>res),catchError(this.handleError('Employee project',[])));
  }

  addProductForEmployee(employeeProductData:any):Observable<any>{
    return this.http.post(`${API_URL}${EMPLOYEE_PRODUCT_URL}`,employeeProductData).pipe(map((res:any)=>res),catchError(this.handleError('Employee product',[])));
  }

  addProvisionForEmployee(employeeProvisionData:any):Observable<any>{
    let toSendEmployee:any ={};
    toSendEmployee.productcost = employeeProvisionData.Projects;
    return this.http.post(`${API_URL}${EMPLOYEE_PROVISION_URL}/${employeeProvisionData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('Employee provision',[])));
  }

  addSalaryForEmployee(employeeCompensationData:any):Observable<any>{
    let toSendEmployee:any ={};
    toSendEmployee.projectcost = employeeCompensationData.salaries;
    return this.http.post(`${API_URL}${EMPLOYEE_INDIVIDUAL_SALARY_URL}/${employeeCompensationData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('Employee compensation',[])));
  }


  updateProvisionForEmployee(employeeProvisionData:any):Observable<any>{
    let toSendEmployee:any ={};
    toSendEmployee.productcost = employeeProvisionData.Projects;
    return this.http.put(`${API_URL}${EMPLOYEE_PROVISION_URL}/${employeeProvisionData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('Employee provision',[])));
  }

  updateSalaryForEmployee(employeeCompensationData:any):Observable<any>{
    let toSendEmployee:any ={};
    toSendEmployee.projectcost = employeeCompensationData.salaries;
    return this.http.put(`${API_URL}${EMPLOYEE_INDIVIDUAL_SALARY_URL}/${employeeCompensationData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res),catchError(this.handleError('Employee compensation',[])));
  }

  addDepartmentCompensation(employeeDepCompensationData):Observable<any>{
    let toSendEmployee:any ={};
    toSendEmployee.departmentcost = employeeDepCompensationData.salaries;
    return this.http.post(`${this.employee_department_salary}/${employeeDepCompensationData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res));
  }

  updateDepartmentCompensation(employeeDepCompensationData:any):Observable<any>{
    let toSendEmployee:any ={};
    toSendEmployee.departmentcost = employeeDepCompensationData.salaries;
    return this.http.put(`${this.employee_department_salary}/${employeeDepCompensationData.Employee_id}`,toSendEmployee).pipe(map((res:any)=>res));
  }

  getDepartmentCompensation(employeeDepCompensationData):Observable<any>{
    return this.http.get(`${this.employee_department_salary}/employee/${employeeDepCompensationData.Employee_id}`).pipe(map((res:any)=>res));
  }

  getEmployeeAttendanceStatuswiseSummary(id:number,data):Observable<any>{
    return this.http.post(`${this.employee_attendance_statuswise_summary}/${id}`,data).pipe(map((res:any) => res));
  }

  deleteLeave(id:any):Observable<any>{
    return this.http.delete(`${this.employee_delete_leave}/${id}`).pipe(map((res:any) => res),catchError(this.handleError('deleteLeave')));
  }

  profileUpload(profileImages:any,userID):Observable<any>{
    let formData:FormData = new FormData();
    formData.append('fileSelect', profileImages);
    return this.http.post(`${this.profile_img_upload_url}/${userID}`,formData).pipe(map((res:any) => res),catchError(this.handleError('deleteLeave')));
  }

  statusChange(datas:any):Observable<any>{
    return this.http.put(`${this.status_change_api_url}`,datas).pipe(map((res:any)=>res));
  }

  addEnquiry(enquiryData):Observable<any>{
    return this.http.post(`${this.save_enquiry}`,enquiryData).pipe(map((res:any)=>res));
  }

  updateEnquiry(enquiryData):Observable<any>{
    return this.http.put(`${this.save_enquiry}`,enquiryData).pipe(map((res:any)=>res));
  }
 
  getEnquiryById(enquiryId):Observable<any>{
    return this.http.get(`${this.get_enquiry_by_id}/${enquiryId}`).pipe(map((res:any)=>res));
  }

  getAllEnquiriesByEmployeeId(userId):Observable<any>{
    return this.http.get(`${this.get_all_enquires_for_employee}/${userId}`).pipe(map((res:any)=>res));
  }

  getAllRequestTypes():Observable<any>{
    return this.http.get(`${this.get_request_type_list}`).pipe(map((res:any)=>res));
  }

  getAllEnquiriesByStatusByEmployeeId(enquiryReq,userId):Observable<any>{
    return this.http.post(`${this.get_all_enquiries_by_status_for_employee}/${userId}`,enquiryReq).pipe(map((res:any)=>res));
  }

  getAllEmployees(userStatus?:any,signedStatus?:boolean):Observable<Array<EmployeeList>>{
    let employeeStatus = 'Active';
    if(!userStatus && !signedStatus){
      employeeStatus = 'Inactive';
    }
    if(!userStatus && signedStatus){
      employeeStatus = 'AdminNotSigned';
    }
    return this.http.get(this.employee_api_url_alt+'/GetAll/'+employeeStatus).pipe(map((res:any) => res),catchError(this.handleError('employeeList',[])));
  }
  
  private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			return throwError(result);
		};
  }

  empTargetList(empData:any):Observable<any>{
    return this.http.post(`${this.emp_targetList_api_url}`,empData).pipe(map((res:any)=>res));
  }
  summaryMonthlyBonus(data:any):Observable<any>{
    return this.http.post(`${this.monthly_bonus_summary_api_url}`,data).pipe(map((res:any)=>res));
  }
  summaryBonus(data:any):Observable<any>{
    return this.http.post(`${this.bonus_summary_api_url}`,data).pipe(map((res:any)=>res));
  }

}
