import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { ApiEndpointConstants } from '../../models/api-endpoint-constants';

@Injectable()
export class 
SharedService {

  // private API_URL = ApiEndpointConstants.api_url;
  // private EMPLOYEE_URL = ApiEndpointConstants.ROUTES.EMPLOYEE_NEW;
  // private EMPLOYEE_URL_ALT = ApiEndpointConstants.ROUTES.EMPLOYEE_NEW;
  // private CLIENT_URL = ApiEndpointConstants.ROUTES.CLIENT_NEW;
  // private PROJECT_URL = ApiEndpointConstants.ROUTES.PROJECT;
  // private PRODUCT_URL = ApiEndpointConstants.ROUTES.PRODUCT;
  // private DEPARTMENT_URL = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT;

  // private allEmployeeList:any = [];
  // employeeListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private clientList:any = [];
  // clientListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private projectList:any = [];
  // projectListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private productList:any = [];
  // productListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private hrList:any = [];
  // hrListListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private departmentList:any = [];
  // departmentListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);


  // constructor(private http:HttpClient, private tokenStorage:TokenStorage) { }

  // getRoleAndId():Observable<any>{
  //   let userData:any = {};
  //   userData.role = this.tokenStorage.getUserRoles().pipe(role => role);
  //   userData.userId = this.tokenStorage.getUserId().pipe(id => id);
  //   return userData;
  // }

  // getAllEmployees():Observable<Array<any>>{
  //   return this.http.get(`${this.API_URL}${this.EMPLOYEE_URL_ALT}/GetAll`).pipe(map((res:any) =>res),catchError(this.handleError('employeeList',[])));
  // }

  // getClientList():Observable<any>{
  //   return this.http.get(`${this.API_URL}${this.CLIENT_URL}/GetAll`).pipe(map((res:any)=>res),catchError(this.handleError('clientList')));
  // }

  // getProjectList():Observable<any>{
  //   return this.http.get(`${this.API_URL}${this.PROJECT_URL}/GetAllProject`).pipe(map((res:any)=>res),catchError(this.handleError('projectList')));
  // }

  // getProductList():Observable<any>{
  //   return this.http.get(`${this.API_URL}${this.PRODUCT_URL}/ProductList`).pipe(map((res:any)=>res),catchError(this.handleError('productList',[])));
  // }

  // getRecruiterList():Observable<any>{
  //   return this.http.get(`${this.API_URL}${this.EMPLOYEE_URL}/GetByRole/HR`).pipe(map((res:any)=>res),catchError(this.handleError('HRList',[])));
  // }

  // getDepartmentList():Observable<any>{
  //   return this.http.get(`${this.DEPARTMENT_URL}`).pipe(map((res:any)=>res),catchError(this.handleError('departmentList',[])));
  // }

  // private handleError<T>(operation = 'operation', result?: any) {
	// 	return (error: any): Observable<any> => {
	// 		return throwError(result);
	// 	};
  // }

  // get allEmployeeListData():any{
  //   return this.allEmployeeList;
  // }

  // set allEmployeeListData(employeeListArr:any){    
  //   this.allEmployeeList = employeeListArr;
  // }

  // get clientListData():any{
  //   return this.clientList;
  // }

  // set clientListData(clientListArr:any){
  //   this.clientList = clientListArr;
  // }

  // get projectListData():any{
  //   return this.projectList;
  // }

  // set projectListData(projectListArr:any){
  //   this.projectList = projectListArr;
  // }

  // get productListData():any{
  //   return this.productList;
  // }

  // set productListData(productListArr:any){
  //   this.productList = productListArr;
  // }

  // get hrListData():any{
  //   return this.hrList;
  // }

  // set hrListData(hrListArr:any){
  //   this.hrList = hrListArr;
  // }

  // get departmentListData():any{
  //   return this.departmentList;
  // }

  // set departmentListData(departmentListArr:any){
  //   this.departmentList = departmentListArr;
  // }

  public employeeList$:Observable<any>;
  public clientList$:Observable<any>;
  public projectList$:Observable<any>;
  public productList$:Observable<any>;
  public hrList$:Observable<any>;
  public departmentList$:Observable<any>;
  public activeInactiveEmployeeList$:Observable<any>;
  public roleList$:Observable<any>;

  private API_URL = ApiEndpointConstants.api_url;
  private EMPLOYEE_URL = ApiEndpointConstants.ROUTES.EMPLOYEE_NEW;
  private EMPLOYEE_URL_ALT = ApiEndpointConstants.ROUTES.EMPLOYEE_NEW;
  private CLIENT_URL = ApiEndpointConstants.ROUTES.CLIENT_NEW;
  private PROJECT_URL = ApiEndpointConstants.ROUTES.PROJECT;
  private PRODUCT_URL = ApiEndpointConstants.ROUTES.PRODUCT;
  private DEPARTMENT_URL = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DEPARTMENT;
  private DASHBOARD_URL = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DASHBOARD_COUNT;
  private GET_LEAVE_LIST = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DASHBOARD_LEAVE_LIST;
  private GET_DASHBOARD_EMPLOYEE = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DASHBOARD_EMPLOYEE;
  private APPROVE_LEAVE = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.DASHBOARD_LEAVE;
  private DASHBOARD_LEAVE_ALL = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_LEAVE_LIST;
  private ALL_NOTIFICATIONS = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ALL_NOTIFICATIONS;
  private GET_LEAVE_BY_STATUS = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_ALL_EMPLOYEE_LEAVES_BY_STATUS;
  private GET_EMPLOYEE_LEAVE_BY_STATUS = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_EMPLOYEE_LEAVE_LIST_BY_STATUS;
  private UPDATE_FLIGHT_MODE_STATUS = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.UPDATE_FLIGHT_MODE_STATUS;
  private GET_FLIGHT_MODE_LIST = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.GET_FLIGHT_MODE_LIST;
  private ALL_EMPLOYEE_LIST = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.ALL_ACTIVE_INACTIVE_EMPLOYEES;
  private allEmployeeList:any = [];
  employeeListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  private clientList:any = [];
  clientListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  private projectList:any = [];
  projectListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  private productList:any = [];
  productListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hrList:any = [];
  hrListListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  private departmentList:any = [];
  departmentListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  private profileImg:any='';
  profileImgSub$ : BehaviorSubject<any> = new BehaviorSubject('');
  profileImgSub$$ : Observable<any> = this.profileImgSub$.asObservable();
  notificationSub:BehaviorSubject<any> = new BehaviorSubject('');
  notificationObs$:Observable<any> = this.notificationSub.asObservable();

  private allRoleList:any = [];
  roleListSub$ : BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http:HttpClient, private tokenStorage:TokenStorage) { }

  getRoleAndId():Observable<any>{
    let userData:any = {};
    userData.role = this.tokenStorage.getUserRoles().pipe(role => role);
    userData.userId = this.tokenStorage.getUserId().pipe(id => id);
    return userData;
  }

  public employeeRequest()
  {
   this.employeeList$= this.http.get(`${this.API_URL}${this.EMPLOYEE_URL_ALT}/GetAll`).
   pipe(tap(res=>{return res;}),shareReplay(1))
  }

  getAllEmployees(query?:any):Observable<any>{    
    !this.employeeList$?this.employeeRequest():'';   
    //return this.http.get(`${this.API_URL}${this.EMPLOYEE_URL_ALT}/GetAll`).pipe(map((res:any) =>res),catchError(this.handleError('employeeList',[])));
    return this.employeeList$;
    //return this.http.get(this.employee_new_api_url_alt).pipe(map((res:any) => res),catchError(this.handleError('employeeList',[])));
  }

  public allEmployeeRequest()
  {
   this.activeInactiveEmployeeList$= this.http.get(`${this.ALL_EMPLOYEE_LIST}`).
   pipe(tap(res=>{return res;}),shareReplay(1));
  }


  getAllActiveInactiveEmployeeList():Observable<any>{
    !this.activeInactiveEmployeeList$?this.allEmployeeRequest():'';
    return this.activeInactiveEmployeeList$;
  }

  public clientRequest()
  {
   this.clientList$= this.http.get(`${this.API_URL}${this.CLIENT_URL}/GetAll`).
   pipe(tap(res=>{return res;}),shareReplay(1))
  }
  
  getClientList(query?:any):Observable<any>{    
    !this.clientList$?this.clientRequest():'';  
    return this.clientList$;    
  }

  public projectRequest()
  {
   this.projectList$= this.http.get(`${this.API_URL}${this.PROJECT_URL}/GetAllProject`).
   pipe(tap(res=>{return res;}),shareReplay(1))
  }
  getProjectList(query?:any):Observable<any>{    
    !this.projectList$?this.projectRequest():''; 
    return this.projectList$;    
  }

  public productRequest()
  {
   this.productList$= this.http.get(`${this.API_URL}${this.PRODUCT_URL}/ProductList`).
   pipe(tap(res=>{return res;}),shareReplay(1))
  }
  getProductList(query?:any):Observable<any>{    
    !this.productList$?this.productRequest():''; 
    return this.productList$;
  }
  
  public hrRequest()
  {
   this.hrList$= this.http.get(`${this.API_URL}${this.EMPLOYEE_URL}/GetByRole/HR`).
   pipe(tap(res=>{return res;}),shareReplay(1))
  }
  getRecruiterList(query?:any):Observable<any>{    
    !this.hrList$?this.hrRequest():''; 
    return this.hrList$;
  }

   public departmentRequest()
  {
   this.departmentList$= this.http.get(`${this.DEPARTMENT_URL}`).
   pipe(tap(res=>{return res;}),shareReplay(1))
  }
  getDepartmentList(query?:any):Observable<any>{    
    !this.departmentList$?this.departmentRequest():''; 
    return this.departmentList$;
  }
  private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			return throwError(result);
		};
  }

  get allEmployeeListData():any{
    return this.allEmployeeList;
  }

  set allEmployeeListData(employeeListArr:any){    
    this.allEmployeeList = employeeListArr;
  }

  get clientListData():any{
    return this.clientList;
  }

  set clientListData(clientListArr:any){
    this.clientList = clientListArr;
  }

  get projectListData():any{
    return this.projectList;
  }

  set projectListData(projectListArr:any){
    this.projectList = projectListArr;
  }

  get productListData():any{
    return this.productList;
  }

  set productListData(productListArr:any){
    this.productList = productListArr;
  }

  get hrListData():any{
    return this.hrList;
  }

  set hrListData(hrListArr:any){
    this.hrList = hrListArr;
  }

  get departmentListData():any{
    return this.departmentList;
  }

  set departmentListData(departmentListArr:any){
    this.departmentList = departmentListArr;
  }

  getDashboard(id:any):Observable<any>{
    return this.http.get(`${this.DASHBOARD_URL}/`+id).pipe(map((res:any)=>res),catchError(this.handleError('DASHBOARD',[])));
  }

  getApproveLeave(Data):Observable<any>{
    return this.http.post(`${this.APPROVE_LEAVE}`,Data).pipe(map((res:any)=>res),catchError(this.handleError('Leave Approve',[])));
  }
  getAllLeave(statusObj?:any):Observable<any>{
    return this.http.post(`${this.GET_LEAVE_BY_STATUS}`,statusObj).pipe(map((res:any)=>res),catchError(this.handleError('Get All leave',[])));
  }

  getDashboardLeave(id:any):Observable<any>{
    return this.http.get(`${this.DASHBOARD_LEAVE_ALL}/`+id).pipe(map((res:any)=>res),catchError(this.handleError('Dashboard Leave',[])));
  }

  getDashboardEmployee(id:any):Observable<any>{
    return this.http.get(`${this.GET_DASHBOARD_EMPLOYEE}/`+id).pipe(map((res:any)=>res),catchError(this.handleError('Dashboard Employee',[])));
  }

  getDashboardLeaveByStatus(id:any,statusData):Observable<any>{
    return this.http.post(`${this.GET_EMPLOYEE_LEAVE_BY_STATUS}/`+id,statusData).pipe(map((res:any)=>res),catchError(this.handleError('Dashboard Leave',[])));
  }

  getUserDetails(){
    return this.tokenStorage.getUserData().pipe(data => data);
  }

  getNotifications():Observable<any>{
    return this.http.get(`${this.ALL_NOTIFICATIONS}`).pipe(map((res:any)=>res),catchError(this.handleError('Dashboard Employee',[])));
  }

  updateFlightModeStatus(flightModeData):Observable<any>{
    return this.http.post(`${this.UPDATE_FLIGHT_MODE_STATUS}`,flightModeData).pipe(map((res:any)=>res),catchError(this.handleError('Dashboard Employee',[])));
  }

  getFlightModeList():Observable<any>{
    return this.http.get(`${this.GET_FLIGHT_MODE_LIST}`).pipe(map((res:any)=>res), catchError(err => throwError(err.error)));
  }

  getDefaultRolesAndPermissions():Observable<any>{
    return this.http.get(`${this.EMPLOYEE_URL_ALT}/defaultMenuList`).pipe(map((res:any)=>res),catchError(this.handleError('Menu list',[])));
  }


  public getAllRoles()
  {
   this.roleList$= this.http.get(`${this.API_URL}${this.EMPLOYEE_URL_ALT}/getallroles`).
   pipe(tap(res=>{return res;}),shareReplay(1))
  }

  getRolesList():Observable<any>{    
    !this.roleList$?this.getAllRoles():'';
    return this.roleList$;    
  }
  

}
