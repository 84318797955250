import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { DecimalPipe } from '@angular/common';

@Component({
	selector: 'm-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss'],
	providers:[DecimalPipe]
})
export class BarChartComponent implements OnInit {

	@Input() chartData:any;
	public barChartOptions: any;
	
	public barChartLabels: string[];

	public barChartColors: string[];

	public barChartType: string = 'bar';
	
	@Input() barChartLegend: boolean = true;

	public barChartData: any[] = [];

	@ViewChild(BaseChartDirective) bcd:BaseChartDirective;

	constructor (private numberPipe?:DecimalPipe) { }

	ngOnInit () {
		
		let tempthis = this;
		this.barChartLabels = this.chartData.label;
		this.barChartColors = [this.chartData.backgroundColor];
		if(this.chartData.data && this.chartData.data.datasets){
			for(let i=0;i<this.chartData.data.datasets.length;i++){
				this.barChartData.push({data:this.chartData.data.datasets[i].data,backgroundColor:this.chartData.data.datasets[i].backgroundColor,label:this.chartData.data.datasets[i].label});
			}
			this.barChartLegend = true;
		}else{
			this.barChartData.push({data:this.chartData.data,backgroundColor:this.chartData.backgroundColor});		
			this.barChartLegend = false;
		}
		

		setTimeout(() => {
			this.bcd.chart.update();
		}, 2000);
		let tbc = this;
		this.barChartOptions= {
			scaleShowVerticalLines: false,
			responsive: true,
			plugins: {
				labels: false
			  } ,
			scales : {
				yAxes: [
				  {
					  gridLines : {
					   drawBorder: false
					  },
					  display: true,
					  ticks: {
						callback: function(label) {
						  return label + ' ' + tempthis.chartData.labelunit;
					  },
						fontSize: 10,
						padding: 15,
						fontColor: '#7b7e8a',
						fontFamily: "'Poppins'",
						stepSize: this.chartData.stepSize,
						min:0
					  }
				  }
				],
				xAxes : [ 
					{
						gridLines : {
							display : false
						},
						ticks:{
							fontSize: 10,
							fontColor: '#7b7e8a',
							fontFamily: "'Poppins'",
							labels: {
								style: {
										fontSize: '11.8em'
								}
							}
						},


					}
			 	]
			},
			tooltips:{
				callbacks:{
				  label: function(tooltipItem, data) {
					  	let dataset = data.datasets[tooltipItem.datasetIndex];
					  	// return formatted string here
						return tbc.numberPipe.transform(dataset.data[tooltipItem.index],'','sv-SE');
					},
					afterLabel: function(tooltipItem, data) {
						let dataset = data.datasets[tooltipItem.datasetIndex];
					  	// return formatted string here
						if(data.datasets[2] && data.datasets[2].label == "Månad" && data.datasets[2].data[tooltipItem.index] && (data.datasets[2].data[tooltipItem.index] !== '0.00')){
							if(dataset.label == 'E-checkin' || dataset.label == "Loxysoft" || dataset.label == "Månad"){								
								return tbc.numberPipe.transform((((dataset.data[tooltipItem.index]/data.datasets[2].data[tooltipItem.index])) * 100).toFixed(2),'','sv-SE') +'%';
							}else{
								return '';
							}
					  	}else{
							return '';
					  	}
					}
				}
			}
		};
	}

	// events
	chartClicked (e: any): void {
	}

	chartHovered (e: any): void {
	}

}
