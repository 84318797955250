import { ConfigModel } from "../interfaces/config";

export class MenuList implements ConfigModel{    
    public config:any[];
    constructor(){
        this.config = [
            {
                menuId:40,
                menuViewName:'StatisticsList',
                menuUrl:'/statistics/list'
            },
            {
                menuId:1,
                menuViewName:'mySalaryList',
                menuUrl:'/my-salary/list'
            },
            {
                menuId:2,
                menuViewName:'myAttendance',
                menuUrl:'/my-attendance/calendar'
            },
            {
                menuId:3,
                menuViewName:'mySchedule',
                menuUrl:'/my-schedule'
            },
        
            {
                menuId:4,
                menuViewName:'myLeaveRequests',
                menuUrl:'/my-leave-requests'
            },
            {
                menuId:36,
                menuViewName:'contactRecruitment',
                menuUrl:'/management/recruitment'
            },
            {
                menuId:37,
                menuViewName:'contactAdmin',
                menuUrl:'/management/contact-admin'
            },
            {
                menuId:44,
                menuViewName:'topEmployee',
                menuUrl:'/personal/employee/top'
            },
            {
                menuId:5,
                menuViewName:'employeeInfo',
                menuUrl:'/personal/employee/list'
            },
            {
                menuId:6,
                menuViewName:'attendanceSales',
                menuUrl:'/attendance/sales/report'
            },
            {
                menuId:7,
                menuViewName:'attendanceManagement',
                menuUrl:'/attendance/leader/report'
            },
            {
                menuId:8,
                menuViewName:'attendanceOverview',
                menuUrl:'/attendance/overview'
            },
            {
                menuId:9,
                menuViewName:'empTargetHoursList',
                menuUrl:'/project/emptargetHours/list'
            },
            {
                menuId:10,
                menuViewName:'allEmployeeSchema',
                menuUrl:'/schema'
            },
            {
                menuId:11,
                menuViewName:'leaveRequests',
                menuUrl:'/requests'
            },
            {
                menuId:12,
                menuViewName:'employeeRequests',
                menuUrl:'/employee-requests/list'
            },
            {
                menuId:13,
                menuViewName:'importHours',
                menuUrl:'/import/import-time/list'
            },
            {
                menuId:14,
                menuViewName:'importSales',
                menuUrl:'/import/import-order/list'
            },
            {
                menuId:15,
                menuViewName:'importRegrets',
                menuUrl:'/import/import-regrets/list'
            },
            {
                menuId:16,
                menuViewName:'clientList',
                menuUrl:'/project/client/list'
            },
            {
                menuId:17,
                menuViewName:'projectList',
                menuUrl:'/project/projects/list'
            },
            {
                menuId:18,
                menuViewName:'productList',
                menuUrl:'/project/products/list'
            },
            {
                menuId:19,
                menuViewName:'provisionList',
                menuUrl:'/project/provisions/list'
            },
            {
                menuId:20,
                menuViewName:'hoursList',
                menuUrl:'/project/timersattning/list'
            },
            {
                menuId:21,
                menuViewName:'targetHoursList',
                menuUrl:'/project/targetHours/list'
            },
            {
                menuId:22,
                menuViewName:'benefitList',
                menuUrl:'/project/benefit/list'
            },
            {
                menuId:23,
                menuViewName:'bonusSettingsList',
                menuUrl:'/project/bonusSetting/list'
            },
            {
                menuId:39,
                menuViewName:'narvaroTidList',
                menuUrl:'/project/narvarotid/list'
            },
            {
                menuId:41,
                menuViewName:'trappaList',
                menuUrl:'/project/trappa/list'
            },
            // {
            //     menuId:42,
            //     menuViewName:'trappaNarvaro',
            //     menuUrl:'/project/trappaNarvaro/list'
            // },
            // {
            //     menuId:43,
            //     menuViewName:'trappaGrouping',
            //     menuUrl:'/project/trappaGrouping/list'
            // },
            
            {
                menuId:24,
                menuViewName:'departmentList',
                menuUrl:'/systemsetting/department/list'
            },
        
            {
                menuId:25,
                menuViewName:'departmentCalendar',
                menuUrl:'/systemsetting/department-calendar'
            },
            {
                menuId:38,
                menuViewName:'noticerType',
                menuUrl:'/systemsetting/noticer-type/list'
            },
            {
                menuId:26,
                menuViewName:'employeeRequestSettings',
                menuUrl:'/employee-requests/request-type-settings'
            },
            {
                menuId:27,
                menuViewName:'employeeLogs',
                menuUrl:'/personal/employee/logs'
            },
            {
                menuId:28,
                menuViewName:'qualityReport',
                menuUrl:'/quality/quality-report/list'
            },
            {
                menuId:29,
                menuViewName:'qualitySettingsList',
                menuUrl:'/quality/settings'
            },
            {
                menuId:30,
                menuViewName:'economySalaryTable',
                menuUrl:'/economy/wages/list'
            },
            {
                menuId:31,
                menuViewName:'economyOverviewList',
                menuUrl:'/economy/overview/list'
            },
            {
                menuId:32,
                menuViewName:'economyMangementList',
                menuUrl:'/economy/management/list'
            },
            {
                menuId:33,
                menuViewName:'economyBonusList',
                menuUrl:'/economy/bonus/list'
            },
            {
                menuId:34,
                menuViewName:'menuPermissions',
                menuUrl:'/systemsetting/menu-permissions'
            },
            {
                menuId:35,
                menuViewName:'flightMode',
                menuUrl:'/systemsetting/flight-mode'
            },
            {
                menuId:45,
                menuViewName:'myOrderRequests',
                menuUrl:'/orders/myorder'
            },
        ];


        
    }
}
