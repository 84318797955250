import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild, Inject, } from '@angular/core';
import { LayoutConfigService } from './core/services/layout-config.service';
import { ClassInitService } from './core/services/class-init.service';
import { TranslationService } from './core/services/translation.service';
import * as objectPath from 'object-path';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PageConfigService } from './core/services/page-config.service';
import { filter } from 'rxjs/operators';
import { SplashScreenService } from './core/services/splash-screen.service';
import { AclService } from './core/services/acl.service';
import {Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { TokenStorage } from './core/auth/token-storage.service';
import {Keepalive} from '@ng-idle/keepalive';

// language list
import { locale as enLang } from './config/i18n/en';
import { locale as chLang } from './config/i18n/ch';
import { locale as esLang } from './config/i18n/es';
import { locale as jpLang } from './config/i18n/jp';
import { locale as deLang } from './config/i18n/de';
import { locale as frLang } from './config/i18n/fr';
import { locale as svLang } from './config/i18n/sv';
import { DOCUMENT } from '@angular/common';
import { SharedService } from './core/services/pages/shared.service';
import { MenuListService } from './core/services/pages/menu-list.service';

// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[m-root]',
	templateUrl: './app.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnInit {

	idleState = 'Not started.';
	timedOut = false;
	lastPing?: Date = null;

	title = 'Metronic';

	@HostBinding('style') style: any;
	@HostBinding('class') classes: any = '';

	@ViewChild('splashScreen', {read: ElementRef})
	splashScreen: ElementRef;
	splashScreenImage: string;

	constructor(
		private layoutConfigService: LayoutConfigService,
		private classInitService: ClassInitService,
		private sanitizer: DomSanitizer,
		private translationService: TranslationService,
		private router: Router,
		private pageConfigService: PageConfigService,
		private splashScreenService: SplashScreenService,
		private aclService: AclService,
		private idle: Idle,
		private tokenStorage: TokenStorage,
		private keepalive: Keepalive,
		@Inject(DOCUMENT) private documentObj: Document,
		private sharedService:SharedService
	) {

		this.sharedService.getRolesList();
		this.documentObj.body.addEventListener('mousewheel', function(e:any) {
			e.stopPropagation();
			var max = this.scrollWidth - this.offsetWidth; // this might change if you have dynamic content, perhaps some mutation observer will be useful here
		  
			if (this.scrollLeft + e.deltaX < 0 || this.scrollLeft + e.deltaX > max) {
			  e.preventDefault();
			  this.scrollLeft = Math.max(0, Math.min(max, this.scrollLeft + e.deltaX));
			}
		}, false);

		// sets an idle timeout of 1 hour.
		idle.setIdle(86400);
		// sets a timeout period of 5 seconds. after 1 hour and 5 seconds of inactivity, the user will be considered timed out.
		idle.setTimeout(5);
		// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
		idle.onTimeout.subscribe(() => {
		  this.tokenStorage.getAccessToken().subscribe((token)=>{
			  if(token){
				  this.tokenStorage.clear();
				  location.reload(true);
			  }
		  });
		  this.idleState = 'Timed out!';
		  this.timedOut = true;
		});
		idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
		idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
	
		// sets the ping interval to 15 seconds
		keepalive.interval(15);
	
		keepalive.onPing.subscribe(() => this.lastPing = new Date());
	
		this.reset();

		// subscribe to class update event
		this.classInitService.onClassesUpdated$.subscribe(classes => {
			// get body class array, join as string classes and pass to host binding class
			setTimeout(() => this.classes = classes.body.join(' '));
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			this.classInitService.setConfig(model);

			this.style = '';
			if (objectPath.get(model.config, 'self.layout') === 'boxed') {
				const backgroundImage = objectPath.get(model.config, 'self.background');
				if (backgroundImage) {
					this.style = this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + objectPath.get(model.config, 'self.background') + ')');
				}
			}

			// splash screen image
			this.splashScreenImage = objectPath.get(model.config, 'loader.image');
		});

		// register translations
		this.translationService.loadTranslations(svLang, enLang, chLang, esLang, jpLang, deLang, frLang);

		// override config by router change from pages config
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.layoutConfigService.setModel({page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), 'config')}, true);
			});
	}

	reset() {
		this.idle.watch();
		this.idleState = 'Started.';
		this.timedOut = false;
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		if (this.splashScreen) {
			this.splashScreenService.init(this.splashScreen.nativeElement);
		}
	}
}
