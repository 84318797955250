import { ConfigModel } from '../interfaces/config';

export interface AclInterface {
	permissions: any;
	currentUserRoles: any;
}

export class AclModel implements AclInterface, ConfigModel {
	public config: any;

	// default permissions
	public permissions: any = {
		admin: ['canDoMostThings'],
		superadmin:['employeeInfo', 'mySalaryList'],
		// USER: ['canDoLimitedThings'],
		sales:['canDoLimitedThings'],
		teamleader:['canDoLimitedThings'],
		quality:['canDoLimitedThings'],
		user: ['canDoLimitedThings'],
		recruitment: ['canDoLimitedThings'],
		reception: ['canDoLimitedThings']

		// hr:['canDoMostThings']
	};

	// store an object of current user roles
	public currentUserRoles: any = {};

	constructor() {}
}
