import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';
import { ApiEndpointConstants } from '../models/api-endpoint-constants';

@Injectable()
export class AuthenticationService implements AuthService {
	API_URL = ApiEndpointConstants.api_url;
	API_ENDPOINT_LOGIN = ApiEndpointConstants.ROUTES.LOGIN;
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';
	SCRIVE_REGISTER = this.API_URL+'/EmployeeNew/EmployeeRegistration';
	public onCredentialUpdated$: Subject<AccessData>;
	private Registration_Update_Sign_Url = ApiEndpointConstants.api_url + ApiEndpointConstants.ROUTES.SIGN_UPDATE;
	API_FORGOT_PASSWORD = ApiEndpointConstants.ROUTES.FORGOT_PASSWORD;
    API_RESET_PASSWORD = ApiEndpointConstants.ROUTES.RESET_PASSWORD;
    API_CHANGE_PASSWORD = ApiEndpointConstants.ROUTES.CHANGE_PASSWORD;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService		
	) {
		this.onCredentialUpdated$ = new Subject();
	}

	updateSign(params):Observable<any>{
		let docId = params.docId;
		let status = params.sign_status;
		let role = params.role;
		return this.http.get(`${this.Registration_Update_Sign_Url}/${status}/${docId}/${role}`).pipe(map((res:any) => res));
	}


	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public login(credential: Credential): Observable<any> {
		// Expecting response from API
		// tslint:disable-next-line:max-line-length
		return this.http.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN,'',{
			headers:new HttpHeaders({"Authorization":"Basic "+ btoa(credential.username+":"+credential.password)})
		}).pipe(
			map((result: any) => {
				this.tokenStorage.setAccessToken(result.accessToken);
				return result;
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(this.handleError('login', []))
		);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			//console.log(error); // log to console instead			
			// Let the app keep running by returning an empty result.
			return throwError(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		if (refresh) {
			location.reload(true);
		}
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.tokenStorage
				.setAccessToken(accessData.accessToken)
				.setRefreshToken(accessData.refreshToken)
				// .setUserRoles([accessData.Roles.toLowerCase()])
				// .setUserFullName(accessData.FullName)
				// .setUserEmail(accessData.Email)
				// .setUserId(accessData.Id)
				.setUserData(accessData);
			this.onCredentialUpdated$.next(accessData);
		}
	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	// public register(credential: Credential): Observable<any> {
	// 	// dummy token creation
	// 	credential = Object.assign({}, credential, {
	// 		accessToken: 'access-token-' + Math.random(),
	// 		refreshToken: 'access-token-' + Math.random(),
	// 		roles: ['USER'],
	// 	});
	// 	return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
	// 		.pipe(catchError(this.handleError('register', []))
	// 	);
	// }

	public register(regData: any): Observable<any> {
		return this.http.post(this.SCRIVE_REGISTER, regData)
			.pipe(map((result: any) => {
				return result;
			}));
	}

	

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(data:any): Observable<any> {
		return this.http.post(this.API_URL + this.API_FORGOT_PASSWORD,data)
			.pipe(catchError(this.handleError('forgot-password', []))
		);
    }
    
    public resetPassword(data:any): Observable<any> {
		return this.http.post(this.API_URL + this.API_RESET_PASSWORD,data)
			.pipe(catchError(this.handleError('reset-password', []))
		);
	}

	public changePassword(data:any): Observable<any> {
		return this.http.post(this.API_URL + this.API_CHANGE_PASSWORD,data)
			.pipe(catchError(this.handleError('reset-password', []))
		);
	}

	public getHeaders(token:string):{[name: string]: string | string[];}{
		if(token){
			return {'Authorization':'Bearer '+ token};
		}else{
			return {'Authorization':''};
		}
		
	}

}
