export const locale = {
    lang: 'sv',
    data: {
		TRANSLATOR: {
			SELECT: 'Välj ditt språk',
		},
		MENU: {
			NEW: 'ny',
			ACTIONS: 'Åtgärder',
			CREATE_POST: 'Create New Post',
			REPORTS: 'Reports',
			APPS: 'Appar',
            DASHBOARD: 'Instrumentbräda',
            PERSONAL:{
                PERSONNEL:'Personal',
                ADD_EMPLOYEE:'Lägg till anställd',
				EMPLOYEE_LIST:'Personallista',
				EMPLOYEE_ADD:'Lägg till Anställd ',
				EMPLOYEE_EDIT:'Redigera Anställd',
				EMPLOYEE_VIEW:'Visa Anställd'				
            },
            MY_ACCOUNT:'Mitt konto',
            SCHEDULE:'Schema',
			TOP_EMPLOYEE:'Top Anstalld',
            MY_WAGES:'Mina löner',
			ATTENDANCE_REPORT:'Närvarorapport',
			PERSONNEL:'Personal',
            QUALITY:'Kvalitet',
            IMPORT_ORDER_TIME:'Importera tid & order',
            ECONOMY:'Ekonomi',
			PROJECT_SETTINGS:'Projektinställningar',
			PROJECT_SETTINGS_MENU:{
				CLIENT:'Kund',
				PROJECT:'Projekt',
				PROVISION:'Provision',
				INDIVIDUAL_PROVISION:'Provisioner individ',
				TIMER_SATTNING: 'Timersättning',
				Bonus:'Bonus'
			},
			SYSTEM_SETTINGS:'Systeminställningar',			
			SYSTEM_SETTINGS_MENU: {
				DEPARTMENT:'Avdelning',
				COMPANY_CALENDAR:'Företagskalender',
				COMPANY_CALENDAR_HISTORY:'Historia',
				COMPANY_CALENDAR_LEAVES:'Ledighet lista för företag',
				DEPARTMENT_CALENDAR_HISTORY:'Avdelning kalenderhistoria'
			},
			QUALITY_MENU:{
				ADD:'Lägg till ny',
				SETTINGS:'Kvalitetinställning'
			},
			MY_SALARY:'Min lön',
			MY_ATTENDANCE:'Min närvaro',
			WORK_SCHEDULE:'Arbetsschema',
			TIMER_OVERVIEW:'Tidsöversikt',
			BONUS_TYPE:'Bonustyp',
			RECRUITMENT:'Rekrytering',
			ATTENDANCE_TIME:'Närvaro Tid',
			BONUS:'Trappa Bonus',
			NARVARO:'Trappa Närvaro %',
			MONTHLY_HOURS:'Månadstimmar',
			CONTACT:'Kontakt'
		},
		AUTH: {
			GENERAL: {
				OR: 'Eller',
				SUBMIT_BUTTON: 'Lämna',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Glömt ditt lösenord',
				BACK_BUTTON: 'Tillbaka',
				PRIVACY: 'Integritet',
				LEGAL: 'Rättslig',
				CONTACT: 'Kontakta',
				ERP:'VI ÄR SVERIGES BÄSTA TELEMARKETINGBYRÅ',
				QUOTE:'Goda ting kommer till dem som vet hur man väntar. De bästa sakerna kommer till dem som inte ger upp, utan kämpar.',
				REGISTER:'Registrera'
			},
			LOGIN: {
				TITLE: 'Inloggningskonto',
				BUTTON: 'Logga in',
			},
			FORGOT: {
				TITLE: 'Glömt lösenord?',
				DESC: 'Ange din email för att återställa ditt lösenord',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'E-post',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				FIRSTNAME:'Förnamn',
				LASTNAME:'Efternamn',
				PLACE:'Ort'
			},
			VALIDATION: {
				INVALID: '{{name}} är inte giltigt',
				REQUIRED: '{{name}} krävs',
				MIN_LENGTH: '{{name}} minsta längd är {{min}}',
				AGREEMENT_REQUIRED: 'Godkännande av villkor och villkor krävs',
				NOT_FOUND: 'Det begärda {{name}} hittades inte',
				INVALID_LOGIN: 'Användarnamn eller lösenord är felaktigt'
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Sök',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}			
		},
		COMMON:{
			NEW:'Ny',
			ACTIVE:'Aktiva',
			INACTIVE:'Inaktiv',
			ROLE_LIST:{
				sales:'Säljare',
				developers:'Utveklare',
				admin:'Admin',
				superadmin:'Superadmin',
				recruitment:'Rekrytering',
				seller:'Säljare',				
				quality:'Kvalitet',
				production:'Produktion',
				management:'Förvaltning',
				hr:'HR',
				user:'Användare',
				teamleader:'Teamledare',
				reception: 'Reception'
			},
			EMPLOYEE:'Anställd',
			TRUE:'Sann',
			FALSE:'Falsk',
			CANCEL:'Avbryt',
			DELETE:'Ta bort',
			OWN_REASON:'Egen begaran',
			LOGIN_DEPARTMENT:'Login Avdelning',
			TERMINATION:'Uppsagd',
			EXTENT:'Omfattning',
			VACATION_PAY:'Semesteravtal',			
			MESSAGE:{
				DELETE_EMPLOYEE:'Vill du verkligen ta bort den här medarbetaren. (Anställnings-ID - ',
				REQUIRED:'är obligatoriskt',
				VALID_DATA:'Ange ett giltigt',
				RESEND_CREDENTIALS:'Användarnamn och lösenord skickat till registrerat e-postadress',
				RESEND_DOCUMENT_SIGN_LINK:'Länk till tecknat avtal skickat till registrerat e-postadress',
				DELETE_QUALITY:'Vill du verkligen ta bort den här rapporten (Rapportera ID -',
				COMPLETE_ISSUE:'Är du säker på att du vill markera den här rapporten som klar',
				UNCOMPLETE_ISSUE:'Är du säker på att du vill markera den här rapporten som ej klar'
			},
			LOGOUT:'Logga ut',
			SAVE:'Spara',
			NEXT:'Nästa',
			SAVE_AND_NEXT:'Spara & Nästa',
			SALARY_REPORT:'Lönerapport',
			ATTENDANCE_REPORT:'Närvarorapport',
			SALES_REPORT:'Försäljningsrapport',
			CALENDAR:'Kalender',
			DIAGRAM:'Diagram',
			TABLE:'Tabell',
			REPORT:'Rapporter',
			MESSAGE_LABEL:'Meddelande',
			OK:'Ok',
			EMPLOYEE_LIST:'Anställdslista',
			ORDER_CORRECTION:'Orderkorrigering',
			QUALITY_REPORT:'Kvalitetrapport',
			VIEW_ALL: 'Visa alla',
			ORDER:'Order',
			QUALITY:'Kvaliet',
			ADD_NEW:'Lagg Till',
			CHANGE_ISSUE_STATUS:'Ändra utgångsstatus',
			ECONOMY_MANAGEMENT:'Ledningsekonomi',
			YES:'Ja',
			LEAVE:'Lediget',
			MANAGEMENT:'Ledning',
			SHOW_HIDE_COLUMNS:'Visa/dölj kolumner',
			DONE:'Gjort',
			ERROR:'Fel',
			NO_INFO_FOUND:'Inga uppgifter funna',
			LOADING_ERROR:'Ett fel inträffade vid inläsningen',
			SUCCESS:"Framgång",
			REQUIRED: 'Krävs',
			NO:'Nej',
			FROM_DATE:'Från Datum',
			TO_DATE:'Till Datum',
			WEEK:'Vecka',
			LOG_DETAILS:'Logg Detaljer',
			EDIT:'Redigera',
			BONUS:'Bonus',
			STATUS:'Status',
			PROVISION:'Provision',
			SOMETHING_WENT_WRONG:'Ser ut som något gick fel!',
			NO_FILE:'Ingen fil vald',
			REPLACE:'Ersätta',
			ACTIONS:'Åtgärder',
			INPUT:{
				EMAIL:'E-post',
				MOBILE:'Mobil',
				CITY:'Stad',
				LANDLINE:'Hemtelefon',
				ADDRESS:'Adress',
				PERSONAL_NUMBER:'Personnummer',
			},
			SALES:'Säljare',
			LEADER:'Ledare',
			COMPLETED:'Klara',
			NOT_COMPLETED:'Ej Klara',
			REVENUE:'Intäkt',
			EXPENSE:'Utgift',
			COST:'Kostnad',
			PROFIT:'Vinst',
			NAME:'Namn',
			HOURS:'Timmar',
			ACTUAL:'Verklig',
			BACK:'Tillbaka',
			MONTH:'Månad',
			YEAR:'År',
			DATE:'DATUM',
			REGRET:'Ånger',
			CUT:'Snitt',
			APPROVE:'Godkänna',
			UPDATE:'Uppdatera',
			DEVICE_SETTINGS:'Enhetsinställningar',
			NOTES:'Anteckningar',
			SUCCESS_MESSAGES:{
				QUALITY_CREATE:'Kvalitérapport har skapats',
				QUALITY_UPDATE:'Kvalitérapport har uppdaterats',
				QUALITY_DELETE:'Kvalitérapport har raderats',
				CLIENT_CREATE:'Kund skapad',
				CLIENT_UPDATE:'Kund uppdaterad',
				CLIENT_DELETE:'Kund raderad',
				PROJECT_CREATE:'Projekt skapat',
				PROJECT_UPDATE:'Projekt uppdaterat',
				PROJECT_DELETE:'Projekt raderat',
				PRODUCT_CREATE:'Produkt skapad',
				PRODUCT_UPDATE:'Produkt uppdaterad',
				PRODUCT_DELETE:'Produkt raderad',
				PROVISION_CREATE:'Provision skapad',
				PROVISION_UPDATE:'Provision uppdaterad',
				TIMERSATTNING_CREATE:'Timersättning skapad',
				TIMERSATTNING_UPDATE:'Timersättning uppdaterad',
				DEPARTMENT_CREATE:'Avdelning skapad',
				DEPARTMENT_UPDATE:'Avdelning uppdaterad',
				DEPARTMENT_DELETE:'Avdelning raderad',
				BONUS_TYPE_CREATE:'Förmån skapad',
				BONUS_TYPE_UPDATE:'Förmån uppdaterad',
				BONUS_TYPE_DELETE:'Förmån raderad',
				BONUS_CREATE:'Förmån skapad',
				BONUS_UPDATE:'Förmån uppdaterad',
				EMPLOYEE_INFO_CREATE:'Anställningsinformation skapad',
				EMPLOYEE_INFO_UPDATE:'Anställningsinformation uppdaterad',
				EMPLOYEE_ATTENDANCE_CREATE:'Närvarostatus skapad',
				EMPLOYEE_ATTENDANCE_UPDATE:'Närvarostatus uppdaterad',
				LEADER_ATTENDANCE_CREATE:'Närvarostatus skapad',
				LEADER_ATTENDANCE_UPDATE:'Närvarostatus uppdaterad',
				CREDENTIALS_SENT_TO_EMAIL:'Användarnamn och lösenord skickas till registrerad e-postadress',
				SIGN_LINK_SENT_TO_EMAIL:'Länk till tecknat avtal skickat till registrerad e-postadress'				
			},
			VALIDATION_MESSAGES:{
				VALID_MOBILE:'Vänligen uppge ett giltigt mobilnummer',
				VALID_EMAIL:'Vänligen uppge en giltig e-postadress'
			},
			POPUP_MESSAGES:{
				DELETE_EMPLOYEE:'Vill du verkligen ta bort den anställda',
				DELETE_QUALITY_REPORT:'Vill du verkligen ta bort den här rapporten',
				MARK_REPORT_DONE:'Är du säker på att du vill markera den här rapporten som klar',
			}
		},
		EMPLOYEE:{
			EMPLOYEE_LIST:{
				EMPLOYEE_ID:'Anställnings-ID',
				PERSONAL_ID:'Personnummer',
				FIRST_NAME:'Förnamn',
				LAST_NAME:'Efternamn',
				EMAIL:'E-Post',
				MOBILE:'Mobil',
				DEPARTMENT:'Avdelning',
				LOGIN_DEPARTMENT:'Login Avdelning',
				CITY:'Stad',
				ACTIONS:'Åtgärder',
				STATUS:'Status',
				HOME_PHONE:'Hemtelefon',
				ADDRESS:'Adress',
				ZIPCODE:'Postnummer',
				BANKNAME:'Banknamn',
				CLEARING_NUMBER:'Clearingnummer',
				ACCOUNT_NUMBER:'Kontonummer',
				LOXYSOFT:'Loxysoft',
				LOXYSOFT_ID:'Loxysoft ID',
				EXTENT:'Omfattning',
				EMERGENCY_CONTACT_NAME_1:'Anhörig1 Namn',
				EMERGENCY_CONTACT_NUMBER_1:'Anhörig1 Tel',
				EMERGENCY_CONTACT_NAME_2:'Anhörig2 Namn',
				EMERGENCY_CONTACT_NUMBER_2:'Anhörig2 Tel',
				RECRUITER : 'Rekryterare',
				RECRUITED_BY : 'Värvad av',
				APPROVAL_STATUS:'Godkännande',
				PROJECT:'Projekt',
				CLIENT:'Kund',
				START_DATUM:'Start datum',
				TILL_DATUM:'Till datum'
			},
			OWN_REASON_LIST:{
				schedule:'Arbetstider',
				salary:'Dalig totallön',
				cooperation:'Darig samarbete med ledaran',
				no_vacation:'Far inte beviljad semester',
				work:'Gillar inte arbetsuppgifter',
				far:'Langt till jobbet',
				bonus:'Narvarobonusen',
				idle:'Overksamma tiden',
				competitor:'Ska jobba hos ett konkurrerande bolage',
				student:'Ska stuer',
				environmet:'Trivs inte i arbetsmiljon',
				sell_lot:'Vill inte salja lotter',
				unable_full:'Vill inte/kan inte jobba heltid',
				industry:'Vill jobba i en annan bransch'
			},
			TERMINATION_REASON_LIST:{
				violation:'Bryter mot ordningsregler i produktionen',
				attendance:'Dålig närvaro',
				low_sales:'Når inte upp till försäljningskrav',
				invalid_absence:'Ogiltig frånvaro',
				other:'Övrigt',
				late:'Sena ankomster'
			},
			EXTENT_LIST:{
				full:'Heltid',
				part:'Deltid',
				extra:'Extra',
				holiday:'Semester'
			},
			VACATION_PAY_LIST:{
				salary_12:'12% påslag på lön',
				salary_8:'8% påslag på lön',
				no_pay:'Uttag av semesterdagar'
			},
			GENERAL:{
				PERSONNEL_INFORMATION:'Personalinformation',
				PERSONNEL_INFO:'Personalinfo',
				EMPLOYEE_INFORMATION:'Anställningsinformation',
				PROJECT_AND_CONTRACT:'Kund och Kontrakt',
				CLIENT_AND_CONTRACT:'Kund och avtal',
				LEAVE:'Ledighet',
				INDIVIDUAL_COMMISSION:'Individuell Provision',
				INDIVIDUAL_COMPENSATION:'Individuell timersättning',
				FIXED_PAY:'Fast Lön',
				SOCIAL_FEE:'Sociala Avgifter',
				VACATION_COMPENSATION:'Semesterersättning',
				PERSONNEL_NUMBER:"Personnummer",
				EMPLOYMENT_INFO:'Anställningsinfo',
				EMPLOYMENT_INFORMATION:'Anställningsinformation',
				FAMILY_CONTACT_INFORMATION:'Anhörig kontaktuppgifter',
				PLACE:'Ort',
				RELATIVE_NAME:'Anhörig namn',
				RELATIVE_PHONE:'Anhörig tel',
				INDIVIDUAL_COMPENSATION_BY_DEPARTMENT:'Individuell Ersättning per Avdelning'
			}			
		},
		USER_PROFILE:{
			GENERAL:{
				MY_PROFILE:'Min profil'
			}
		},
        SYSTEM_SETTINGS:{
			COMPANY_CALENDAR:'Företagskalender',
			DEPARTMENT:'Avdelning',
			COMPANY_CALENDAR_HISTORY:'Företagets kalenderhistoria',
			COMPANY_CALENDAR_LEAVES:'Företagskalenderledighet',
			DEPARTMENT_CALENDAR_HISTORY:'Avdelning kalenderhistoria'
		},
		MONTH_VALUES:{
			January:'Januari',
			February:'Februari',
			March:'Mars',
			April:'April',
			May:'Maj',
			June:'Juni',
			July:'Juli',
			August:'Augusti',
			September:'September',
			October:'Oktober',
			November:'November',
			December:'December',
		}
	}
};