import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TokenStorage } from '../../../../../core/auth/token-storage.service';
import { SharedService } from '../../../../../core/services/pages/shared.service';
import { ApiEndpointConstants } from '../../../../../core/models/api-endpoint-constants';
import { ProfileService } from '../../../../pages/header/profile/_core/services/profile.service';

@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles:[
		`
		.m-topbar_user{
			color:#ec7727;
			text-decoration:none;
			margin-top:10px;
		}
		.m-nav__link {
			position: relative;
			margin: 5px 5px !important;
		}
		.m-topbar_user_first_name{
			padding-left: 5px;
			padding-right: 10px;
			font-size: 1.1rem;
			font-weight: 500;
		}
		.m--img-rounded-small{
			width: 40px;
			height: 40px;
			border-radius: 40px;
		}		
		.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__email {
			color: #ffffff;
		}
		.m-card-user .m-card-user__details .m-card-user__email {			
			font-weight: 500;
		}

		.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__name{
			color: #ffffff;
		}
		
		.user-initial{
			width: 60px;
			height: 60px;
			border-radius: 100%;
			background: #f3f3f3;
			font-size: 26px;
			color: #f06a0e;
			text-align: center;
			line-height: 53px;
			padding: 5px;
			font-weight: 500;
		}

		.user-initial-mini{
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: #f3f3f3;
			font-size: 15px;
			color: #f06a0e;
			text-align: center;
			line-height: 45px;
			padding: 12px 8px 12px 13px;
			font-weight: 600;
		}
		`
	],
	providers:[ApiEndpointConstants,ProfileService]
})
export class UserProfileComponent implements OnInit, OnDestroy {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/app/media/img/users/user3.jpg';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

	avatarName:any;
	avatarEmail:any;
	userRole:any;
	userInitials:string;
	userLoginData:any;
	profileImgObs:any;

	constructor (
		private router: Router,
		private authService: AuthenticationService,
		private sanitizer: DomSanitizer,
		private tokenStorage: TokenStorage,
		private SharedService:SharedService,
		private endpoints:ApiEndpointConstants,
		private _ref:ChangeDetectorRef,
		private profileService:ProfileService
	) {}

	ngOnInit (): void {
		this.avatar = '';
		if (!this.avatarBg) {
			this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.jpg)');
		}
		this.getAvatarName().subscribe(name => {
			this.avatarName = name;
		});

		this.getAvatarEmail().subscribe(email =>{
			this.avatarEmail = email;
		})

		this.tokenStorage.getUserRoles().subscribe((role)=>{
			this.userRole = role[0];
		});

		this.tokenStorage.getUserData().subscribe((udata)=> {
			this.userLoginData = udata;			
			this.userInitials = this.userLoginData.FirstName.charAt(0).toUpperCase() + this.userLoginData.LastName.charAt(0).toUpperCase();
			this.getUserData(this.userLoginData.Id);
			this._ref.detectChanges();
		});

		this.profileImgObs = this.SharedService.profileImgSub$$.subscribe((imgStr) => {
			if(imgStr){
				this.avatar = this.endpoints.app_images_url+imgStr;
				this._ref.detectChanges();
			}
		});
	}

	ngOnDestroy(){
		this.profileImgObs.unsubscribe();
	}

	public logout () {
		this.authService.logout(true);
	}

	getAvatarName(){
		return this.tokenStorage.getUserFullName().pipe(name => name);
	}
	getAvatarEmail(){
		return this.tokenStorage.getUserEmail().pipe(emailId => emailId);		
	}

	getUserData(id){
		this.profileService.getProfile(id).subscribe((profileData)=> {
			this.avatar = profileData.img ? this.endpoints.app_images_url+profileData.img : '';
			this._ref.detectChanges();
		});
	}
}
