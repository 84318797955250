import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'm-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  public pieChartType:string = 'pie';
  pieChartOptions: ChartOptions;
  pieChartLegend : true
  
  @Input() loader:boolean;
  @Input() pieChartLabels:string[];
  @Input() pieChartData:number[];
  @Input() pieChartColors:Array<any>;

  constructor(private _ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.pieChartOptions = this.createOptions();
    this.pieChartLegend = true;
  }
  private createOptions(): ChartOptions {
    return {
      responsive: true,
          maintainAspectRatio: true,
          plugins: {
              labels: {
                render: 'percentage',
                fontColor: 'black',
                fontSize:13,
                precision: 2,
                position: 'outside',
              }
          },
         
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            padding: 10,
          }
        },
        animation: {
          duration: 4000,
      }
    };
  }
  ngOnChanges() {
   this._ref.detectChanges();
  }   
  public chartClicked(e:any):void {
  }
 
  public chartHovered(e:any):void {
  }
}
