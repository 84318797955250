export class ApiEndpointConstants {
    //public static api_url = 'https://viafone-dev-api.azurewebsites.net/api';
    public static api_url = 'https://viafoneerp.azurewebsites.net/api';
    public app_images_url = 'https://viafront.azurewebsites.net/profile-images/';
    public static agreement_doc_url = 'https://viafront.azurewebsites.net/document';
    // public static api_url = 'http://185.247.117.138/api';
    public static ROUTES = {
        EMPLOYEE:'/employee',
        EMPLOYEE_ALT:'/Employee',
        EMPLOYEE_NEW:'/EmployeeNew',
        EMPLOYEE_LEAVE_DATA:'/Attendance/GetbyEmployeepermonthwithstatus&dayscount',
        EMPLOYEE_EDIT_BANK:'/EmployeeNew/EditBankDetails',
        EMPLOYEE_EDIT_EMPLOYMENT:'/EmployeeNew/EditEmployeeEmployment',
        EMPLOYEE_EDIT_AGREEMENT:'/EmployeeNew/EditAgreement',
        EMPLOYEE_EDIT_LEAVE:'/EmployeeNew/EditEmployeeLeave',
        EMPLOYEE_EDIT_FIXED_SALARY:'/EmployeeNew/EditEmployeeFixedSalary',
        EMPLOYEE_EDIT_SOCIAL_FEE:'/EmployeeNew/EditEmployeeSocialFee',
        EMPLOYEE_EDIT_HOLIDAY_PAY:'/EmployeeNew/EditEmployeeHolidayPay',
        LOGIN:'/auth/token',
        CLIENT:'/ClientNew',
        EMP_TYPE:'/Emp_type',
        DEPARTMENT:'/Department',
        BONUS:'/bonus',
        MONTHLY_SALARY_PERIOD:'/Econmy/getEconomySalaryProjectMonthlyReport',
        CLIENT_NEW:'/ClientNew',
        PROJECT:'/Project',
        PRODUCT:'/Product',
        ADD_PROJECT:'/Project/AddProject',
        ADD_PRODUCT:'/Product/AddProduct',
        GET_PROVISION:'/ClientXProductCost/GetAllProductCost',
        INDIVIDUAL_PROVISION_LIST: '/EmployeeXProductCost/GetAllProvision',
        PROVISION:'/ClientXProductCost',
        TIMERSATTNING:'/ClientXProjectCost/GetAllProjectCost',
        COPYPROJECTCOST:'/ClientXProjectCost',
        COPYPRODUCTCOST:'/ClientXProductCost',
        CRUD_TIMERSATTNING:'/ClientXProjectCost',
        EMPLOYEE_PROJECT:'/EmployeeXProject',
        EMPLOYEE_PRODUCT:'/EmployeeXProduct',
        EMPLOYEE_PROVISION:'/EmployeeXProductCost',
        EMPLOYEE_INDIVIDUAL_SALARY:'/EmployeeXProjectCost',
        ATTENDANCE:'/Attendance',
        DEPARTMENT_REPORT_LEADER_VIEW: '/Attendance/AttendanceViewLeader',  
        DEPARTMENT_REPORT_LEADER_REPORT: '/Attendance/AttendanceDefaultReportLeader',  
        ATTENDANCE_SALES_REPORT: '/Attendance/AttendancDefaultReportSales',
        DEPARTMENT_REPORT: '/Attendance/GetDepartmentwiseReport',
        DEPARTMENT_TOTAL_HOURS: '/Attendance/DepartmentwiseTotalhours',
        PROJECT_STATISTICS_ATTENDANCE: '/Attendance/StatuswiseTotalhours',
        ATTENDANCE_STATISTICS_REPORT:'/Attendance/GetDepartmentwiseReportfordaterange',
        ATTENDANCE_STATISTICS_TOTALHOURS:'/Attendance/GetDepartmentwiseReportfordaterangeTotalhours',
        ATTENDANCE_STATISTICS_TOTAL_REPORT:'/Attendance/GetDepartmentwiseReportfordaterangeTotalhours',
        SIGN_UPDATE:'/EmployeeNew/signUpdate',
        GET_ATTENDANCE_STATUS_BY_DATERANGE:'/Attendance/GetDepartmentwiseReportfordaterange',
        COMPANY_CALENDAR_SCHEDULE:"/schedule/GetAllCompanyWorkSchedule",
        COMPANY_CALENDAR:"/schedule/CompanyMonthlySchedule",
        COMPANY_CALENDAR_INFO_UPDATE:"/schedule/DayInfoUpdate",
        ADD_COMPANY_CALENDAR:"/schedule/CompanyWorkSchedule",
        SALES_FILE_UPLOAD:'/File/SalesFileUpload',
        TIMES_FILE_UPLOAD:'/File/HoursFileUpload',
        SALES_FILE_ADD:'/importOrder/AddSalesFile',
        SALES_FILE_EDIT:'/File/EditsalesDetails',
        TIME_FILE_EDIT:'/File/EdithoursDetails',
        TOTAL_ORDER_EDIT:'/Econmy/orderUpdate',
        SALES_LIST:'/File/GetAllSalesDetails',
        HOURS_LIST:'/File/GetAllHoursDetails',
        ECONOMY:'/Econmy',
        ECONOMY_OVERVIEW_LIST:'/File/GetEconomyOverview',
        ECONOMY_SALARY_PERIOD_LIST:'/File/getEconomySalaryPeriodReport',
        ECONOMY_SALARY_PERIOD_PRODWISE_LIST:'/File/getEconomySalaryPeriodProductWiseReport',
        ECONOMY_SALARY_PERIOD_PROFILE:'/Econmy/getEconomySalaryEmployeeMonthlyReport',
        ECONOMY_ACTUAL_REVENUE_UPDATE:'/File/UpdateProjectRevenue',
        REGRETS_FILE_UPLOAD:'/File/RegretFileUpload',
        REGRETS_FILE_ADD:'/File/EditregretDetails',
        REGRETS_FILE_EDIT:'/File/EditregretDetails',        
        REGRETS_LIST:'/File/GetAllRegretDetails',
        QUALITY:'/Quality',
        ORDER_CORRECTION_LIST:'/GetOrderCorrectionDetails',
        QUALITY_REPORT_LIST:'/GetQualityReportDetails',
        EMPLOYEE_CALENDAR_SCHEDULE:"/schedule/GetAllEmployeeWorkSchedule",
        EMPLOYEE_CALENDAR:"/schedule/EmployeeMonthlySchedule",
        EMPLOYEE_CALENDAR_INFO_UPDATE:"/schedule/EmployeeDayInfoUpdate",
        ADD_EMPLOYEE_CALENDAR:"/schedule/EmployeeWorkSchedule",
        EMPLOYEE_DEPARTMENT_SALARY:'/EmployeeXDepartmentCost',
        ECONOMY_MANAGEMENT_SALARY: '/Econmy/getManagementSalaryReport',
        ECONOMY_MANAGEMENT_SALARY_UPDATE: '/Econmy/UpdateActualSalary',
        LOG_HOURS:'/File/GetAllHoursLog',
        LOG_SALES:'/File/GetAllSalesLog',
        LOG_REGRET:'/File/GetAllRegretLog',
        DEPARTMENT_CALENDAR_SCHEDULE:"/schedule/GetAllCompanyHolidaySchedule",
        DEPARTMENT_CALENDAR:"/schedule/DepartmentMonthlySchedule",
        DEPARTMENT_CALENDAR_INFO_UPDATE:"/schedule/DepartmentWorkSchedule",
        ADD_DEPARTMENT_CALENDAR:"/schedule/DepartmentWorkSchedule",
        ATTENDANCE_SALES_REPORT_CLIENTWISE:'/Attendance/clientwisesalesattendance',
        ATTENDANCE_SALES_VIEW_CLIENTWISE:'/Attendance/clientwisesalesstatus',
        ATTENDANCE_LEADER_REPORT_DEPARTMENTWISE:'/Attendance/AttendanceReportLeaderDepwise',
        ATTENDANCE_LEADER_VIEW_DEPARTMENTWISE:'/Attendance/AttendanceViewLeaderDepwise',
        ATTENDANCE_EMPLOYEE_STATUSWISE_SUMMARY : '/attendance/attendancesummary',
        FORGOT_PASSWORD:'/EmployeeNew/forgotpassword',
        RESET_PASSWORD:'/EmployeeNew/resetpassword',
        CHANGE_PASSWORD:'/EmployeeNew/changepassword',
        DASHBOARD_COUNT:'/EmployeeNew/EmployeeDashboard',
        DELETE_LEAVE:'/schedule/DeleteCompanyWorkSchedule',
        ATTENDANCE_MONTHLY_CHART_DATA:'/attendance/getEmpMonthlyData',
        DASHBOARD_LEAVE_LIST:'/EmployeeNew/getAllDashboardLeaveList',
        GET_LEAVE_LIST:'/EmployeeNew/EmployeeDashboardLeaveList',
        DASHBOARD_EMPLOYEE:'/EmployeeNew/EmployeeDashboard',
        DASHBOARD_LEAVE:'/EmployeeNew/EmployeeLeaveApprove',
        EMPLOYEE_DELETE_LEAVE:'/EmployeeNew/EmployeeLeaveDelete',
        EMPLOYE_PERSONAL_SCHEDULE:'/schedule/EmployeePersonalMonthlySchedule',
        USER_PROFILE_PHOTO_UPLOAD:'/File/EmployeeImgUpload',
        EMPLOYEEE_MONTHLY_SALARY_DATA: '/Econmy/getEconomySalaryProjectMonthlyReport',
        USER_ATTENDANCE_DETAILS:'/Attendance/narvarolist',
        LOCK_ATTENDANCE_BY_DATE:'/Attendance/lockday',
        CHANGE_ATTENDANCE_STATUS:'/Attendance/changestatus',
        ATTENDANCE_OVERVIEW:'/Attendance/attendanceoverview',
        COMPANY_LEAVE_HISTORY:'/Schedule/CompanyLeaveList',
        COMPANY_LEAVE_UPDATE:'/Schedule/CompanyLeaveUpdate',
        CLIENT_LIST:'/clientNew/GetAll',
        ASSIGN_CLIENT_FOR_EMPLOYEE:'/EmployeeXProject/AddClient',
        ALL_NOTIFICATIONS:'/EmployeeNew/notificationcount',
        DEPARTMENT_SCHEDULE_HISTORY:'/Schedule/GetDepartmentScheduleList',
        DEPARTMENT_SCHEDULE_HISTORY_UPDATE:'/Schedule/DepartmentScheduleUpdate',
        GET_DEPARTMENT_HISTORY_BY_GROUP:'/Schedule/GetDepartmentWorkSchedule',
        LOXYSOFT_HISTORY : '/EmployeeNew/EmployeeLoxysoftHistory',
        TARGET_SETTINGS_LIST:'/EmployeeNew/loxyhourssettingslist',
        UPDATE_TARGET_SETTINGS:'/EmployeeNew/loxyhourssettings',
        SEARCH_TARGET_SETTINGS:'/EmployeeNew/loxyhourstargetlist',
        SEARCH_EMP_TARGET_SETTINGS:'/EmployeeNew/loxyhoursemployeetargetlist',
        PROFILE_STATUS_CHANGE:'/EmployeeNew/changeStatus',
        CLEAR_DEVICE_ID:'/EmployeeNew/ClearDeviceId',
        GET_ALL_EMPLOYEE_LEAVES_BY_STATUS:'/EmployeeNew/getAllStatusLeaveList',
        GET_ALL_REQUEST_TYPE_LIST:'/EmployeeNew/GetAllTypeSettings',
        ADD_REQUEST_TYPE:'/EmployeeNew/addTypeSetting',
        EDIT_REQUEST_TYPE:'/EmployeeNew/editTypeSetting',
        GET_REQUEST_TYPE_BY_ID:'/EmployeeNew/gettypesetting',
        DELETE_REQUEST_TYPE:'/EmployeeNew/deletetypesetting',
        GET_ALL_ENQUIRIES:'/EmployeeNew/GetEnquiry',
        SAVE_ENQUIRY:'/EmployeeNew/enquiry',
        GET_ENQUIRY_BY_ID:'/EmployeeNew/GetEnquiry',
        GET_ALL_ENQUIRIES_FOR_EMPLOYEE:'/EmployeeNew/GetAllEmployeeEnquiry',
        CHANGE_ENQUIRY_STATUS:'/EmployeeNew/changestatusenquiry',
        GET_ALL_ENQUIRIES_BY_STATUS:'/EmployeeNew/GetAllEnquirybystatus',
        GET_ALL_ENQUIRIES_BY_STATUS_FOR_EMPLOYEE:'/EmployeeNew/GetAllEmployeeEnquirybystatus',
        GET_EMPLOYEE_LEAVE_LIST_BY_STATUS:'/EmployeeNew/EmployeeDashboardLeaveByStatus',
        UPDATE_FLIGHT_MODE_STATUS:'/EmployeeNew/updateflightmode',
        GET_FLIGHT_MODE_LIST:'/EmployeeNew/FlightModeList',
        GET_ALL_EMPLOYEE_WITH_COUNT:'/EmployeeNew/GetAllEmployee',
        SEND_CALENDAR_NOTFICIATION:'/Schedule/CompanyLeaveNotify',
        ADD_EMPLOYEE_AGREEMENT: '/EmployeeNew/EditAgreementSendDoc',
        EDIT_EMPLOYEE_AGREEMENT:'/EmployeeNew/EditAgreements',
        EMPLOYEE_LOGS:'/EmployeeNew/EmployeeLogsHistory',
        GET_ALL_ACTIVE_QUALITY_SETTINGS:'/Quality/GetActiveQualitySettings',
        UPDATE_ECONOMY_MONTHLY_SUMMARY_DETAILS:'/Econmy/UpdateEconomySummaryData',
        ATTENDANCE_OVERVIEW_SUMMARY_DETAILS:'/Attendance/narvarosummary',
        ATTENDANCE_OVERVIEW_SUMMARY:'/Attendance/narvarooverviewsummary',
        SINGLE_DAY_SCHEMA:'/Schedule/SingleDaySchema',
        ATTENDANCE_HOURLY_CHARTDATA:'/Schedule/EmployeeMonthlyHoursDetails',
        EMPLOYEE_SCHEDULE_HISTORY_LIST:'/Schedule/GetEmployeeScheduleList',
        EMPLOYEE_BONUS:'/Bonus/GetEmployeeRecuritmentBonusSummary',
        EMPLOYEE_BONUS_MONTHLY:'/Bonus/GetEmployeeRecuritmentBonus',
        BONUS_LIST:'/EmployeeNew/recuritmentbonussettingslist',
        ADD_BONUS_LIST:'/EmployeeNew/recuritmentbonussettings',
        ALL_ACTIVE_INACTIVE_EMPLOYEES:'/EmployeeNew/GetAllEmpList',
        GET_RECRUITED_EMPLOYEES_BY_USER:'/EmployeeNew/getemployeebyrecuriter',
        GET_RECRUITED_EMPLOYEE_BONUS:'/EmployeeNew/recuritBonusbyemployee',
        GET_RECRUITER_BONUS_BY_RECRUITED:'/EmployeeNew/recuritbonusbyrecuriter',
        EMPLOYEE_ATTENDANCE_CHECKIN: '/Attendance/narvarocheckin',
        GET_EMPLOYEES_BY_DATE_RANGE:'/GetAllEmployeeByDate',
        MONTHLY_SALARY_REPORT_DOWNLOAD:'/Econmy/getMonthlySalaryReport',
        PROVISION_FILE_IMPORT:'/file/ProductCostingUpload',
        HOURLY_SALARY_FILE_IMPORT:'/file/ProjectCostingUpload',
        EMPLOYEE_TARGET_HOURS_SETTINGS:'/EmployeeNew/employeeloxyhourssettingslist',
        EMPLOYEE_EDIT_LOXYSOFT_HISTORY:'/EmployeeNew/EditEmployeeLoxysoftHistory',
        EMPLOYEE_ADD_LOXYSOFT_HISTORY:'/EmployeeNew/AddEmployeeLoxysoft',
        EMPLOYEE_ADD_DEPARTMENT_HISTORY:'/EmployeeNew/AddEmployeeDepartment',
        EMPLOYEE_EDIT_DEPARTMENT_HISTORY:'/EmployeeNew/EditEmployeeDepartment',
        EMPLOYEE_LIST_DEPARTMENT_HISTORY:'/EmployeeNew/EmployeeDepartmentHistory',
        EMPLOYEE_REFEREDBY_HISTORY:'/EmployeeNew/EmployeeReferedByHistory',
        EMPLOYEE_EDIT_REFEREDBY_HISTORY:'/EmployeeNew/EmployeeReferedByUpdate',
        EMPLOYEE_SOCIAL_FEE_HISTORY:'/EmployeeNew/EmployeeSocialFeesHistory',
        EMPLOYEE_EDIT_SOCIALFEE_HISTORY:'/EmployeeNew/EmployeeSocialFeesUpdate',
        EMPLOYEE_REFERENCE_LIST:'/EmployeeNew/EmployeeReference',
        EMPLOYEE_REFERENCE_ADD:'/EmployeeNew/addreference',
        CONTACT_COMPLAINT_ADD:'/EmployeeNew/complaints',
        TRAPPA_BONUS_LIST:'/ClientXProductCost/GetProductCost',
        TOP_THREE_EMPLOYEEE_LIST:'/EmployeeNew/topemployeelist',
        ADD_TRAPPA_BONUS:'/ClientXProductCost/GrundProductCost',
        EDIT_TRAPPA_BONUS:'/ClientXProductCost/GrundProductCost',
        NARVARO_TID_LIST:'/ClientXProjectCost/GetNarvaroTid',
        ADD_NARVARO_TID:'/ClientXProjectCost/NarvaroTid',
        NOTICER_LIST:'/Schedule/NotificationList',
        ADD_NOTICER:'/Schedule/CompanyNotificationUpdate',
        STATISTICS_DATA:'/EmployeeNew/EmployeeStatisticsFilter',
        GET_ALL_CHAT:'/Quality/getchatdetails',
        // ALL_TRAPPA_LIST:'/ClientXProductCost/GetAllTrappaBonus',
        ALL_TRAPPA_LIST:'/Product/GetAllTrappaProjectGroups',
        TRAPPA_NARVARO_LIST:'/Bonus/GetAllNarvaroPercentage',
        ADD_TRAPPA_NARVARO:'/Bonus/AddNarvaroPercentage',
        EDIT_TRAPPA_NARVARO:'/Bonus/EditNarvaroPercentage',
        DELETE_TRAPPA_NARVARO:'/Bonus/DeleteNarvaroPercentage',
        TRAPPA_GROUP_LIST:'/Product/GetAllTrappaGroups',
        ADD_TRAPPA_GROUP:'/Product/AddTrappaGroup',
        EDIT_TRAPPA_GROUP:'/Product/EditTrappaGroup',
        DELETE_TRAPPA_GROUP:'/Product/DeleteTrappaGroup',
        TRAPPA_DETAILS:'/ClientXProductCost/GetTrappaProjectGroup',
        TRAPPA_BONUS_DETAILS:'/ClientXProductCost/getTrappaBonus',
        NARVARO_PERCENTAGE_BY_MONTH:'/Bonus/GetNarvaroPercentageByMonth',
        GET_ALL_TRAPPA_CLIENT:'/ClientNew/GetAllTrappaClient',
        GET_ALL_TRAPPA_CLIENT_PRODUCT:'/Product/GetClientTrappaProvisionProduct',
        GET_ALL_TRAPPA_CLIENT_PROJECT:'/Project/GetClientTrappaProvisionProject',
        ADD_TRAPPA_PROJECT_GROUP:'/Product/AddTrappaProjectGroup',
        EDIT_TRAPPA_PROJECT_GROUP:'/Product/EditTrappaProjectGroup',
        DELETE_TRAPPA_PROJECT_GROUP:'/Product/DeleteTrappaProjectGroup',
        DELETE_TRAPPA:'/ClientXProductCost/DeleteGrundProductCost',
        RECRUITMENT_DATA:'/EmployeeNew/GetAllRecruitmentCity'
    }
}