import { AclModel } from '../models/acl';
import { Injectable } from '@angular/core';
import { ConfigData } from '../interfaces/config-data';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { from, BehaviorSubject, Subject } from 'rxjs';
import { mergeMap, filter } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { SharedService } from './pages/shared.service';
import { MenuListService } from './pages/menu-list.service';
import { TokenStorage } from '../auth/token-storage.service';

@Injectable()
export class AclService implements ConfigData {
	public aclModel: AclModel;
	public onAclUpdated$: BehaviorSubject<AclModel>;
	roleListArr:any[] = [];
	constructor(
		private roleService: NgxRolesService,
		private permService: NgxPermissionsService,
		private authService: AuthenticationService,
		private sharedService:SharedService,
		private menuListService:MenuListService,
		private tokenStorage:TokenStorage
	) {
		// set initial permission model
		this.aclModel = new AclModel();
		this.onAclUpdated$ = new BehaviorSubject(this.aclModel);		

		this.tokenStorage.getUserData().subscribe(uData => {
			if(uData.Roles){
				this.setCurrentUserRoles([uData.Roles],uData.mpermission);
			}
		});

		// subscribe to credential changed, eg. after login response
		this.authService.onCredentialUpdated$
			.pipe(mergeMap(accessData => this.tokenStorage.getUserData()))
			.subscribe(uData => { 
				if(uData.Roles){
					this.setCurrentUserRoles([uData.Roles],uData.mpermission)
				}
			});

		// subscribe to acl data observable
		this.onAclUpdated$.subscribe(acl => {
			let permissions:string[] = []
			Object.keys(acl.permissions).map((key) => {				
				acl.permissions[key].forEach((pName) => {
					permissions.push(pName);
				});
			});
			// load default permission list
			this.permService.loadPermissions(permissions, (permissionName, permissionStore) => {				
				return !!permissionStore[permissionName];
			});
			let definedPermissions = this.permService.getPermissions();
			// merge current user roles
			const roles = Object.assign({}, this.aclModel.currentUserRoles, {
				// default user role is GUEST
				GUEST: () => {
					// return this.authService.isAuthorized().toPromise();
				}
			});
			// add to role service
			this.roleService.addRoles(roles);
		});
	}

	/**
	 * Set AclModel and fire off event that all subscribers will listen to
	 * @param aclModel
	 */
	setModel(aclModel: AclModel): any {
		aclModel = Object.assign({}, this.aclModel, aclModel);
		this.onAclUpdated$.next(aclModel);
	}

	setCurrentUserRoles(roles: any,permissions?:any): any {
		// update roles if the credential data has roles
		if (roles != null) {
			this.aclModel.currentUserRoles = {};
			let currentUserPermissions = this.menuListService.getAndSetUserMenuList(permissions);
			roles.forEach(role => {
				if(currentUserPermissions.length > 0){
					this.aclModel.permissions[role] = currentUserPermissions;
				}
				this.aclModel.currentUserRoles[role] = this.aclModel.permissions[role];
			});
			// set updated acl model back to service
			this.setModel(this.aclModel);
		}
	}
}
