import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { SubheaderService } from '../../../core/services/layout/subheader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'm-subheader',
	templateUrl: './subheader.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles:[
		`.m-subheader{
			padding:10px 30px 6px;
		}

		@media (min-width:320px) and (max-width: 767px){
			.m-subheader .m-subheader__breadcrumbs.m-nav{
				margin-top:0;
			}
		}
		
		`
	]
})
export class SubheaderComponent implements OnInit {	
	constructor(public subheaderService: SubheaderService,private translate:TranslateService) {}

	ngOnInit(): void {}
}
